import React, {useState, useEffect, Fragment} from 'react'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Slide from '@mui/material/Slide';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import apiClient from "../../request/http-common";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setRedirect } from '../Auth/historySlice';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { CardActions } from '@mui/material';
import { QRCode } from 'react-qrcode-logo';
import TextField from '@mui/material/TextField';
import { CardContent } from '@mui/material';
import Card from '@mui/material/Card';
import { useMutation } from 'react-query';
import { setFundings } from '../Wallet/walletSlice';
import {CardHeader, Divider} from '@mui/material';


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

const Seller = () => {
  const [error, setErros] = useState('');

    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);
    const navigate = useNavigate();
    const trader = useSelector((state) => state.coins.p2pTrader);
    const [coin, setCoin] = useState('');
    const dispatch = useDispatch();
    const [amount, setAmount] = useState(null);
    const [bank, setBank] = useState(null);
    const [details, setDetails] = useState(null);

    const [buttonText, setbuttonText] = useState(null);
  

    const { isLoading: isSendingRequest, mutate: postRequest } = useMutation(

        async () => {
          return await apiClient.post(`/api/save-funding`, {
            amount: amount,
            coin: trader.id,
            wallet_name: bank,
            wallet_address: details
          });
        },
        {
          onSuccess: (res) => {
            dispatch(setFundings(res.data.fundings))
            setCoin('');
    
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
            setTimeout(() => {
              navigate(`/payment/${res.data.funding.id}`);
            }, 2000);
          },
          onError: (err) => { 
            // setCoin('');
            if (err.response?.data.length) {
              let myerror = err.response?.data || err;         
            //   setErros(myerror.errors)  
              const event2 = new Event('processed');
              window.dispatchEvent(event2); 
            }else{
              
              let errMessage = err.response?.data.message || err;     
    
              localStorage.setItem('notification', JSON.stringify({message : `${errMessage}`, 'type': 'error' }));
              const event = new Event('newMessage');
              window.dispatchEvent(event);
              const event2 = new Event('processed');
              window.dispatchEvent(event2); 
            }
                
          },
        }
      );


  return (
    <div style={{marginTop: '70px'}}>
        
        <List sx={{ width: '100%', bgcolor: 'transparent' }}>

            <Fragment key={trader.id}>
        <ListItem alignItems="flex-start" sx={{cursor: 'pointer'}}>
            <ListItemText
            primary={trader.trader_name}
            secondary={
                <React.Fragment>
                

                    <Box edge="end" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignContent: 'flex-start', alignItems: 'flex-start'}}>
                        <Typography sx={{fontSize: '11px'}} color="text.primary">
                        Quantity: {formatPrice(trader.quantity)}
                        </Typography>

                        <Typography sx={{fontSize: '11px'}}>
                        Limits: {formatPrice(trader.min)} - {formatPrice(trader.max)}
                        </Typography>

                        <Typography style={{fontWeight: 'lighter'}} variant="caption" display="block" gutterBottom>
                            {trader.payment_description}
                        </Typography>
                    </Box>

                </React.Fragment>
            }
            />

            

            <Box edge="end" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignContent: 'space-between', alignItems: 'center'}}>
                <Typography mx={1} sx={{fontSize: '11px'}} >
                    <Typography>
                    {trader.orders} Orders 
                    </Typography>
                    <Divider orientation="vertical" />
                    <Typography>
                    {parseInt(trader.percentage)}%
                    </Typography>
                </Typography>

            </Box>
            
        </ListItem>
        <Divider variant="inset" component="li" />
        </Fragment>
        </List>

        <Box>
            <Card>
                <Typography variant="h6">
                  ADD YOUR PAYMENT INFORMATION
                </Typography>
                <Divider />
                {/* <CardContent sx={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'start'}}>
                    <Typography variant="subtitle2">
                   {trader.wallet_type === 'bank'? 'Payment Bank:' : 'Coin Name:'}  {trader.coin_name}
                    </Typography>

                    <Typography variant="subtitle2">
                    {trader.wallet_type === 'bank'? 'Bank Details:' : 'Wallet Address'}  {trader.address}
                    </Typography>
                </CardContent> */}
            </Card>
        </Box>

        <Card className='box' sx={{ borderRadius: '9px', height: '100%', margin: 1}}>
           

            <CardContent sx={{textAlign: 'center'}}> 

            

            <FormControl fullWidth={true} sx={{  minWidth: 50, marginBottom: 2 }}>
                <InputLabel htmlFor="amount">Amount (USDT)</InputLabel>
                {(error !== '' && error.amount) ?

                <OutlinedInput
                onChange={(e)=>setAmount(e.target.value)}
                error
                id="amount"
                type={'number'}
                name="amount"
                label="Amount (USDT)"
                helperText={error.amount}
                />
                :
                <OutlinedInput
                onChange={(e)=>setAmount(e.target.value)}
                id="amount"
                type={'number'}
                name="amount"
                label="Amount (USDT)"
                />
                }
                <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                {error !== '' && error.amount}
                </Typography>
            </FormControl>

            <FormControl fullWidth={true} sx={{  minWidth: 50, marginBottom: 2 }}>
                <InputLabel htmlFor="bank">Bank Name</InputLabel>
                {(error !== '' && error.bank) ?

                <OutlinedInput
                onChange={(e)=>setBank(e.target.value)}
                error
                id="bank"
                type={'text'}
                name="bank"
                label="Bank Name"
                helperText={error.bank}
                />
                :
                <OutlinedInput
                onChange={(e)=>setBank(e.target.value)}
                id="bank"
                type={'text'}
                name="bank"
                label="Bank Name"
                />
                }
                <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                {error !== '' && error.bank}
                </Typography>
            </FormControl>

            <FormControl fullWidth={true} sx={{  minWidth: 50, marginBottom: 2 }}>
                <InputLabel htmlFor="details">Account Details</InputLabel>
                {(error !== '' && error.details) ?

                <OutlinedInput
                onChange={(e)=>setDetails(e.target.value)}
                error
                id="details"
                type={'text'}
                name="details"
                label="Account Details"
                helperText={error.details}
                />
                :
                <OutlinedInput
                onChange={(e)=>setDetails(e.target.value)}
                id="details"
                type={'text'}
                name="details"
                label="Account Details"
                />
                }
                <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                {error !== '' && error.details}
                </Typography>
            </FormControl>

            </CardContent>

            <CardActions> 
            <Button onClick={()=>setCoin('')} fullWidth={true} edge="end" size="small" color="error" variant="contained"> 
            Cancel
            </Button> 

            <Button onClick={postRequest} fullWidth={true} edge="end" size="small" color="primary" variant="contained"> 
            {isSendingRequest? 'sending...' : 'Submit Order'} 
            </Button>

            </CardActions>
        </Card>

        <Card sx={{marginTop: '20px'}}>
            <CardContent sx={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'start'}}>
                <Typography variant="h6" gutterBottom>
                    Advertiser Terms
                </Typography>
                <Typography variant="body2">
               {trader.terms}
                </Typography>
            </CardContent>
        </Card>
    </div>
  )
}

export default Seller
