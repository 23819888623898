import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { CardActions } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Slider from "react-slick";

export default function BlogSlider() {
  
  const scrolltoTop = () => window.scrollTo(0, 0);
    const dispatch = useDispatch();
    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);
  const messages = useSelector((state) => state.messages.messages);


    const settings = {
      autoplay: true,
      pauseOnHover: true,
      autoplaySpeed: 3000,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 2,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

  return (
    <div>
    
    {messages.length > 0 && 
      <Card sx={{marginTop: '5px', paddingX: '10px'}} elevation={0}>
      <Slider {...settings}>
      {messages.map((message, index) => (
        <div>
        <Card sx={{ display: 'flex', alignItems: 'center',  justifyContent: 'space-between', marginX: '5px', borderRadius: '10px', marginBottom: '10px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Link onClick={()=> {scrolltoTop()}}  to={message.external_link} component={RouterLink} underline="none" color="inherit">
            <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography sx={{textTransform: 'capitalize'  }} gutterBottom variant="subtitle2" >
            {message.title}
            </Typography>
            
            </CardContent>
                
            </Link>   
          </Box>
          <Box>
          <Link onClick={()=> { scrolltoTop()}}  to={message.external_link} component={RouterLink} underline="none" color="inherit">
            <CardMedia
              component="img"
              sx={{ height: 80, width: 80, borderRadius: 40 }}
              image={message.link}
              alt={message.title}
            />
            </Link> 
          </Box>
        </Card>
        </div>

      ))}
    </Slider>
    
    
  </Card>
    }
        
</div>

)}