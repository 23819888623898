import React, {useState, useEffect, Fragment} from 'react';
import Slider from "react-slick";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import { useSelector } from 'react-redux';
import ReactCardFlip from 'react-card-flip';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { useMutation } from "react-query";
import { Link as RouterLink } from 'react-router-dom';

import apiClient from "../../request/http-common";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import ImportExportIcon from '@mui/icons-material/ImportExport';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const NewCard = () => {
  
  const user = useSelector((state) => state.user.user);
  const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);
  const fundingBal = useSelector((state) => state.wallet.fundingBalance);
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const coins = useSelector((state) => state.coins.coins);

  const [isFlipped, setFlipped] = useState(false);
    const [crdType, setCardType] = useState('');

    const getBTCbalance = () => {
      let bal = 0;
      Object.keys(coins).forEach(coin => {
        if (coins[coin].symbol === 'BTC') {
          bal = (fundingBal/coins[coin].price).toFixed(8) ;
        }
      });

      return bal;
    }

  const handleClick = (e) => {
      e.preventDefault();
      setFlipped(!isFlipped);
    }

    const initialCards = {
        number1: 'XXXX',
        number2: 'XXXX',
        number3: 'XXXX',
        number4: 'XXXX',
        name: 'XXXXX XXXXXX',
        expiry: 'XX/XX',
        cvv: 'XXX',
        type: 'visa'
    }

    const updateCradDetails = (card) => {
        setCurrentCard({...currentCard, 
            number1: card.number1,
            number2: card.number2,
            number3: card.number3,
            number4: card.number4,
            name: user.name,
            expiry: '08/2029',
            cvv: '745',
            type: card.card_type
        })
    }

    const [currentCard, setCurrentCard] = useState(initialCards);
    const getDate = (date) => new Date(date).toDateString();

    // useEffect(() => {

    //   setTimeout(() => {
    //     setFlipped(true);
    //   }, 3000);

    //   setTimeout(() => {
    //     setFlipped(false);
    //   }, 5000);


    // }, []);

  return (
    <div>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={5}>
        <Card sx={{marginTop: '75px', padding: '5px', bgcolor: 'transparent'}} elevation={0}>
        <div>
            <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
                <Box>
                  <Card className='box' onClick={handleClick} sx={{marginX: '5px', height: '80px', borderRadius: '8px', padding: '5px', marginBottom: '10px', bgcolor: '#36492d', boxShadow: '#acf002 0px 1px 5px 0px' }}>

                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start'}}>
                    
                      <Box px={1}  sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', position: 'relative', alignItems: 'flex-start'}} gutterBottom variant="subtitle2" component="div">
                          <Typography  variant="h6">
                          BALANCE
                          </Typography>
                          <Typography variant="h6">
                          {formatPrice(fundingBal)}
                          </Typography>
                      </Box>

                      <Box sx={{display: 'flex', padding: '8px', justifyContent: 'space-between', alignItems: 'flex-start'}}>
                        <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />}
>
                          {/* <Avatar component={RouterLink} to='/scan-code' variant="rounded" sx={{ width: 24, height: 24 }}>
                            <QrCodeScannerIcon />
                          </Avatar>

                          <Avatar ml={2} variant="rounded" sx={{ width: 24, height: 24 }}>
                            <ContentCopyIcon />
                          </Avatar> */}

                          <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730439342/buy_arlyr0.png' ml={2} variant="rounded" sx={{ width: 24, height: 24, bgcolor: '#7f9429' }}>
                            {/* <ImportExportIcon /> */}
                          </Avatar>

                        </Stack>
                      </Box>
                    </Box>
                  </Card>
                </Box>

                <Box>
                <Card onClick={handleClick} sx={{marginX: '5px', height: '80px', borderRadius: '10px', marginBottom: '10px', bgcolor: '#36492d'}}>
                    <Typography px={2} py={1}  sx={{display: 'flex', justifyContent: 'flex-start', fontWeight: 'bold', fontSize: 16, position: 'relative'}} gutterBottom variant="subtitle2" component="div">
                        <Typography sx={{textAlign: 'left'}}  variant="h6" >
                        {getBTCbalance()} BTC                            
                        </Typography>
                    </Typography>
                </Card>
                </Box>
            </ReactCardFlip>
        </div>
      </Card>
      
      </Grid>

      </Grid>

    </div>
  )
}

export default NewCard
