import React, {Fragment} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import WalletSlide from '../Wallet/WalletSlide';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';


import WalletButton from '../Wallet/WalletButton';
import WithdrawalButton from '../Withdrawal/WithdrawalButton';
import Card from '@mui/material/Card';
import { CardActions } from '@mui/material';



import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import DangerousIcon from '@mui/icons-material/Dangerous';
import DoneAllIcon from '@mui/icons-material/DoneAll';


const Transactions = () => {

  const addressText = (address) => {
    let text = String(address) || address;
    let last6 = text.slice(text.length - 6);
    let first3 = text.substring(0,6);
    return first3+'***'+last6;
  }

    const user = useSelector((state) => state.user.user);
    const depayTransactions = useSelector((state) => state.history.depayTransactions);
    const wallets = useSelector((state) => state.history.wallets);
    const fundings = useSelector((state) => state.wallet.fundings);
    const withdrawals = useSelector((state) => state.history.withdrawals);
    

    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

    const allTransactions = fundings.concat(depayTransactions);

    const combinedTransactions = allTransactions.sort((b, a) => {
        return (a.created_at).localeCompare((b.created_at))
    })

    const pluswithdrawal = combinedTransactions.concat(withdrawals);
    const finalHistory = pluswithdrawal.sort((b, a) => {
      return (a.created_at).localeCompare((b.created_at))
  })

    
  return (
    <div>

{/* <WalletSlide/> */}
      {/* <Card >
        <CardActions sx={{justifyContent: 'space-between'}}>

        <WalletButton/>        

        <WithdrawalButton/>

        </CardActions>
      </Card> */}

{/* <Typography mt={4} px={3} sx={{ marginTop: '10px', fontWeight: 'bold', fontSize: 20, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
        TRANSACTION HISTORY
</Typography> */}

{finalHistory.length > 0 &&
<List sx={{ width: '100%' }}>

{finalHistory.map(transaction => (
   <Fragment key={transaction.id}>
  <ListItem alignItems="flex-start">
    
  <ListItemAvatar>
    
    {transaction.status === 'pending' &&
      <Avatar  sx={{ bgcolor: '#f7a21d' }}>
       <PendingIcon/>
      </Avatar>
    }

{transaction.status === 'awaiting confirmation' &&
      <Avatar  sx={{ bgcolor: '#f7a21d' }}>
       <PendingIcon/>
      </Avatar>
    }

{transaction.status === 'processing' &&
      <Avatar  sx={{ bgcolor: '#fcaa00' }}>
       <PendingIcon/>
      </Avatar>
    }
       
    {transaction.status === 'approved' &&
     <Avatar  sx={{ bgcolor: '#37dc8a' }}>
       <CheckCircleIcon/>
      </Avatar>
     }

{transaction.status === 'successful' &&
     <Avatar  sx={{ bgcolor: '#37dc8a' }}>
       <CheckCircleIcon/>
      </Avatar>
     }

    {transaction.status === 'paid' &&
     <Avatar  sx={{ bgcolor: '#37dc8a' }}>
       <CheckCircleIcon/>
      </Avatar>
     }

    {transaction.status === 'declined' &&
    <Avatar  sx={{ bgcolor: '#dd0a22' }}>
      <DangerousIcon/>
    </Avatar>
     
     }

    {transaction.status === 'completed' &&
    <Avatar  sx={{ bgcolor: '#ccc6c6' }}>
      <DoneAllIcon/>
    </Avatar>
     
     }
    
     
  </ListItemAvatar>

  <ListItemText
    primary={transaction?.after_block || transaction.gateway || transaction.coin_name}
    secondary={
      <React.Fragment>
        <Typography
          component="span"
          variant="caption" display="block"
          color="text.primary"
        >
          {/* {transaction.created_at} */}
          { addressText(transaction?.uuid || transaction.transaction_id || transaction.address) }
        </Typography>
      </React.Fragment>
    }
  />
   

    <Box edge="end" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-even', alignContent: 'left', alignItems: 'left'}}>
        <Typography mt={1} edge="end">
       {transaction.coin_name && '-'} {formatPrice(transaction.local_amount || transaction.amount)}
        </Typography>
        <Typography color={(transaction.status.toLowerCase() === 'approved' || transaction.status.toLowerCase() === 'successful') ? 'primary.success': '#fcaa00'}  edge="end">
        {transaction.status}
        </Typography>
    </Box>

  
</ListItem>
<Divider  component="li" variant="inset" />
</Fragment>
))}
</List>
}
    </div>
  )
}

export default Transactions
