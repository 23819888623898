import React, {useState, useEffect, Fragment} from 'react'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';

import apiClient from "../../request/http-common";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setRedirect } from '../Auth/historySlice';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import Divider from '@mui/material/Divider';

import ListSubheader from '@mui/material/ListSubheader';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { CardActions } from '@mui/material';
import { Currency } from '@depay/local-currency';
import { useQuery } from 'react-query';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { setDepayTransactions } from '../../request/Analysis/hitorySlice';
import { QRCode } from 'react-qrcode-logo';
import TextField from '@mui/material/TextField';
// import {DialogTitle} from '@mui/material';
import { CardContent } from '@mui/material';
import Card from '@mui/material/Card';
import { useMutation } from 'react-query';
import Drawer from '@mui/material/Drawer';
import { setFundings } from './walletSlice';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';


import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const WalletButton = () => {
  const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    const addresses = useSelector((state) => state.coins.addresses);
    const isAuth = useSelector((state) => state.auth.isAuthenticated);
    const [coin, setCoin] = useState('');

    const dispatch = useDispatch();
    const [amount, setAmount] = useState(0);
    const [buttonText, setbuttonText] = useState(null);
    const [amountText, setAmountText] = useState(null);
    const [open, setOpen] = React.useState(false);
  
  const handleClickOpen = () => {
       
    if (isAuth && user.name) {
      setOpen(true);
    }else{
      let path = window.location.pathname;
      dispatch(setRedirect(path));
      navigate('/login');
    }
    
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [open2, setOpen2] = React.useState(false);

  const { isLoading: isSendingRequest, mutate: postRequest } = useMutation(

    async () => {
      return await apiClient.post(`/api/save-funding`, {
        amount: amount,
        coin: coin.id
      });
    },
    {
      onSuccess: (res) => {
        dispatch(setFundings(res.data.fundings))
        handleClose();
        setCoin('');

        localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
        const event = new Event('newMessage');
        window.dispatchEvent(event);
        const event2 = new Event('processed');
        window.dispatchEvent(event2);
        setTimeout(() => {
          navigate('/transactions');
        }, 2000);
      },
      onError: (err) => { 
        handleClose();
        setCoin('');
        if (err.response?.data.length) {
          let myerror = err.response?.data || err;         
          // setErros(myerror.errors)  
          
          const event2 = new Event('processed');
          window.dispatchEvent(event2); 
        }else{
          
          let errMessage = err.response?.data.message || err;     

          localStorage.setItem('notification', JSON.stringify({message : `${errMessage}`, 'type': 'error' }));
          const event = new Event('newMessage');
          window.dispatchEvent(event);
          const event2 = new Event('processed');
          window.dispatchEvent(event2); 
        }
            
      },
    }
  );


  return (
    <div>
      
      <Button onClick={()=>setOpen(true)} startIcon={<AttachMoneyIcon />} variant="contained" size='small' color="primary">
      Deposit
      </Button>


      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open2}
      >
      <CircularProgress color="inherit" />
      <Typography my={2} variant="body2" color="success">
          Processing Payment...!
      </Typography>
      </Backdrop>

          <Drawer
            sx={{zIndex: 60000}}
            anchor={'bottom'}
            open={open}
            onClose={()=>handleClose(open)}
          >
            <Box sx={{padding: '10px', marginBottom: '20px'}}>
                <List sx={{ width: '100%', maxWidth: 360 }}>

                  <ListItem component={RouterLink} to="/p2p" sx={{marginY: '2px', borderRadius: '5px', bgcolor: 'background.paper'}}>
                    <ListItemAvatar>
                      <Avatar src='https://res.cloudinary.com/dqi4rtyvh/image/upload/v1738672662/peer-to-peer_1_nd9prb.png'>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText color='primary' primary={<Typography sx={{color: 'text.primary'}}>
                      P2P Trading
                    </Typography>} secondary="Pay with bank transfer, check and more" />
                  </ListItem>

                  <Divider variant="inset" component="li" />
                  <ListItem sx={{marginY: '2px', borderRadius: '5px', bgcolor: 'background.paper'}}>
                    <ListItemAvatar>
                      <Avatar src='https://res.cloudinary.com/dqi4rtyvh/image/upload/v1738672669/credit-card_jiug0l.png'>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Credit/Debit Card" secondary="Visa, Mastercard, JCB, Apple and more" />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem component={RouterLink} to="/receive" sx={{marginY: '2px', borderRadius: '5px', bgcolor: 'background.paper'}}>
                    <ListItemAvatar>
                      <Avatar src='https://res.cloudinary.com/dqi4rtyvh/image/upload/v1738672665/cryptocurrency_i9vuqz.png'>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary=
                    {<Typography sx={{color: 'text.primary'}}>
                    Deposit Crypto
                  </Typography>}
                    secondary="Already have crypto ? Deposit directly" />
                  </ListItem>
                </List>
            </Box>
          </Drawer>

    </div>
  )
}

export default WalletButton
