import React, {Fragment, useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider } from '@mui/material';
import Avatar from '@mui/material/Avatar';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Chip from '@mui/material/Chip';
import LinearProgress from '@mui/material/LinearProgress';

import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ListItemButton from '@mui/material/ListItemButton';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import LockClockIcon from '@mui/icons-material/LockClock';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Popover from '@mui/material/Popover';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import AddchartIcon from '@mui/icons-material/Addchart';
import { useNavigate } from 'react-router-dom';

import { setCopyings } from '../../request/Analysis/hitorySlice';
import { useDispatch } from 'react-redux';
import { useMutation } from "react-query";
import apiClient from "../../request/http-common";
import { Currency } from '@depay/local-currency';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';



import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';

import ReactApexChart from 'react-apexcharts';
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";


// for new payment method
import { QRCode } from 'react-qrcode-logo';
import TextField from '@mui/material/TextField';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import CircularProgress from '@mui/material/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TraderSingle = () => {
  
  const user = useSelector((state) => state.user.user);
  const coinRates = useSelector((state) => state.coins.coinRates);
  const addresses = useSelector((state) => state.coins.addresses);

  const appMode = useSelector((state) => state.mode.mode);

  const params = useParams();
  const dispatch = useDispatch();
  const [rate, setRate] = useState(1);

  const calculateBalance = (coin) => coin.balance === null ? 0 : coin.balance

  const [traderId, settraderId] = useState();
  const [selectedtrader, setSelectedtrader] = useState('');
  const [source, setSource] = useState('');
  const [proportion, setProportion] = useState('');
  const [error, setErros] = useState('');
  const navigate = useNavigate();

  const callRate = async()=> {
    if(user.currency === 'USD'){
      setRate(1);
    }else{
      await Currency.fromUSD({ amount: 1 }).then(amount=>{
        setRate(amount.amount);
       });
    }  
};



  const traders = useSelector((state) => state.traders.traders);
  const getDate = (date) => new Date(date).toLocaleString('en-GB', {
    hour12: false,
  });

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const calcPercentage = (profit, loss) => {
    let total = parseFloat(profit) + parseFloat(loss);
    let percent = profit/total;

    return percent * 100;

 }
 
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

   // for new balance method
   const [buttonText, setbuttonText] = useState(null);
   const [selectedCoin, setSelectedCoin] = useState(null);

   const getCoinDetails = (coinName) => {
     switch (coinName) {
       case 'Overall Asset':
         setSelectedCoin(null) 
         break;

       case 'Referral Wallet':
         setSelectedCoin(null) 
         break;
     
       default:
         addresses.forEach(address => {
           if (address.coin_name === coinName) {       
             setSelectedCoin(address)            
           }
         });
         break;
     }
   }
 

  const { isLoading: isInvesting, mutate: postInvestment } = useMutation(
    async () => {
      return await apiClient.post(`/api/store-copy`, {
        trader_id: traderId,
        amount: amount, 
        proportion: proportion,
        payment_source: source
      });
    },
    {
      onSuccess: (res) => {
          if (res.data.status === 'success') {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            dispatch(setCopyings(res.data.investments));
            setTimeout(() => {
              navigate('/copy-history');
            }, 2000);
          }            
      },
      onError: (err) => {
            // let errorMessage = err.response?.data.message || err ;
            // localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
            // const event = new Event('newMessage');
            // window.dispatchEvent(event);

            if (err.response?.data.length) {
              let myerror = err.response?.data || err;         
          setErros(myerror.errors)               
          }else{
              let errorMessage = err.response?.data.message || err ;
              localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
              const event = new Event('newMessage');
              window.dispatchEvent(event);
          }
      },
    }
  );

  
  const [open, setOpen] = React.useState(false);
  const [amount, setAmount] = useState(0);
  const [currentTrader, setcurrentTrader] = useState(null);

  const fundingBal = useSelector((state) => state.wallet.fundingBalance);
    const subBal = useSelector((state) => state.wallet.subscriptionBalance);
    const refBal = useSelector((state) => state.wallet.referralBalance);
    const mainBal = useSelector((state) => state.wallet.withdrawableBalance);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getSeriesdata = (amount) => {
    
    let data = []
    amount = parseFloat(amount);
    let date = Math.floor(Date.now() / 1000);
    for (let i = 0; i <= 50; i++) {
      let open = (Math.random() * ((amount + 50) - (amount)) + amount).toFixed(2);
      let high = (Math.random() * ((amount + 60) - (amount + 50)) + (amount + 50)).toFixed(2);
      let low = (Math.random() * ((amount - 50) - (amount)) + amount).toFixed(2);
      let close = (Math.random() * ((amount + 50) - (amount)) + amount).toFixed(2);            

      data.push({
        x: date + i,
        y: [parseFloat(open), parseFloat(high), parseFloat(low), parseFloat(close)]
      });
    }

    return data;
  }


  
  // const series =  ;

const options = {
  chart: {
    type: 'candlestick',
    foreColor: '#939c92',
    height: 350,
    toolbar: {
      show: false
    }
  },
  dataLabels: {
    enabled: false
  },
  tooltip: {
    theme: 'dark'
  },
  xaxis: {
    theme: 'dark',
    type: 'datetime'
  },
  yaxis: {
    tooltip: {
      enabled: true
    }
  },
  fill: {
    opacity: 1
  },
};


  return (
    <div style={{marginTop: '75px'}}>

      {traders.map(trader => (
        parseInt(trader.id) === parseInt(params.id) &&
        
        <Fragment>
        <Grid container px={2} mt={4}  mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6}>
            <Box sx={{display:'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>

              <ListItem alignItems="flex-start" mb={0}>
                <ListItemAvatar>
                  <Avatar alt="trader" src={trader.image} />
                </ListItemAvatar>
                <ListItemText
                  primary={trader.trader_name}
                  secondary={trader.level}
                />
              </ListItem>

              <Button fullWidth={true} onClick={()=> {
                handleClickOpen();
                settraderId(trader.id);
                setSelectedtrader(trader);
                setAmount(parseFloat(trader.min_amount))

            }} size="large" variant="contained" >
                set up copying
              </Button>

            </Box>
            <Divider/>
            </Grid>
          </Grid>
          
              <Grid container  sx={{ marginTop: '-15px'}} px={2} mt={4}  mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ width: '100%' }}>

                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                        <Tab label="Summary" {...a11yProps(0)} />
                        <Tab label="Trades" {...a11yProps(1)} />
                      </Tabs>
                    </Box>

                    <CustomTabPanel value={value} index={0}>
                      {/* summary content starts here */}

                      <Card sx={{ borderRadius: '9px', height: '100%', paddingX: '10px'}}>
                        <CardContent sx={{ paddingY: '12px', paddingX: '5px'}}>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingY: '10px'}}>
                                <Typography sx={{fontSize: 12, textTransform: 'capitalize', marginLeft: '10px'  }} gutterBottom variant="subtitle2" component="div">
                                    Minimum Investment
                                </Typography>

                                <Typography sx={{fontSize: 16, textTransform: 'capitalize', marginLeft: '10px'  }} gutterBottom variant="subtitle2" component="div">
                                    {formatPrice(trader.min_amount)}
                                </Typography>
                            </Box>
                            <Divider/>

                            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingY: '10px'}}>
                                <Box>
                                    {parseInt(trader.risk_score) < 3  &&
                                    <Chip label={`No Risk`} color="success" /> 
                                    }
                                    {(parseInt(trader.risk_score) > 2 && parseInt(trader.risk_score) < 5 )&& 
                                    <Chip label={`Low Risk`} color="warning" /> 
                                    }
                                    {parseInt(trader.risk_score) > 4 &&
                                    <Chip label={`Medium Risk`} color="error" /> 
                                    }
                                </Box>

                                <Box>
                                    <IconButton color="secondary" aria-label="add an alarm">
                                      <HelpIcon />
                                    </IconButton>
                                </Box>
                                
                            </Box>
                            <Divider/>

                            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingY: '10px'}}>
                                <Typography sx={{fontSize: 12, textTransform: 'capitalize', marginLeft: '10px'  }} gutterBottom variant="subtitle2" component="div">
                                    {trader.level}
                                </Typography>

                                <Typography sx={{fontSize: 12, textTransform: 'capitalize', marginLeft: '10px'  }} gutterBottom variant="caption" component="div">
                                    <IconButton color="secondary" aria-label="add an alarm">
                                      <HelpIcon />
                                    </IconButton>
                                </Typography>
                            </Box>  
                            <Divider/>

                            
                            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingY: '10px', alignContent: 'center'}}>
                                {/* <Box>
                                    <Typography my={0} variant="caption" color="text.secondary">
                                        Equity
                                    </Typography>
                                    <Typography sx={{ color: '#09b509', fontWeight: 'bold', fontSize: 16, textTransform: 'capitalize'  }} gutterBottom variant="subtitle2" component="div">
                                    {formatPrice(trader.equity)}
                                </Typography>
                                </Box> */}

                                {/* <Box>
                                    <Typography my={0} variant="caption" color="text.secondary">
                                        Commission
                                    </Typography>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: 16, textTransform: 'capitalize'  }} gutterBottom variant="subtitle2" component="div">
                                    {trader.commission}%
                                </Typography>
                                </Box> */}

                                <Box>
                                    <Typography my={0} variant="caption" color="text.secondary">
                                        Duration
                                    </Typography>
                                    <Typography sx={{fontWeight: 'bold', fontSize: 16, textTransform: 'capitalize'  }} gutterBottom variant="subtitle2" component="div">
                                        {trader.duration}d

                                    </Typography>
                                </Box>
                            </Box>


                            {/* <Box sx={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', textAlign: 'left'}}>
                                  <Typography py={1} variant="caption" color="text.secondary">
                                        Strategy Description
                                    </Typography>
                                    <Typography sx={{textAlign: 'left'}} gutterBottom variant="subtitle2" component="div">
                                    {trader.description}
                                </Typography>
                            </Box> */}
                    </CardContent>
                    </Card>

                    <Card sx={{ borderRadius: '9px', height: '100%', paddingX: '10px', marginTop: '5px'}}>
                      <CardContent sx={{ paddingY: '12px', paddingX: '5px'}}>
                          <Typography sx={{fontSize: 18, textAlign: 'left', textTransform: 'capitalize', marginLeft: '10px'  }} gutterBottom variant="subtitle2" component="div">
                              Performance
                          </Typography>

                          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', paddingY: '10px', alignContent: 'flex-start'}}>
                              <Box>
                                  <Typography my={0} variant="caption" color="text.secondary">
                                      Gain
                                  </Typography>
                                  <Typography sx={{ color: '#09b509', fontWeight: 'bold', fontSize: 16, textTransform: 'capitalize'  }} gutterBottom variant="subtitle2" component="div">
                                  {trader.gain}%
                              </Typography>
                              </Box>

                              <Box>
                                  <Typography my={0} variant="caption" color="text.secondary">
                                      Followers
                                  </Typography>
                                  <Typography sx={{ fontWeight: 'bold', fontSize: 16, textTransform: 'capitalize'  }} gutterBottom variant="subtitle2" component="div">
                                  {parseInt(trader.copiers)}{parseInt(trader.copiers) > 999 ? 'M' : 'K'} 
                              </Typography>
                              </Box>

                          </Box> 
                          <Box sx={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'column'}}>
                            <Typography my={0} sx={{textAlign: 'left'}} variant="caption" color="text.secondary">
                                Profit and loss
                            </Typography>

                            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: 16, textTransform: 'capitalize'}} gutterBottom variant="subtitle2" component="div">{formatPrice(trader.profit)}</Typography>

                                <Typography sx={{ fontWeight: 'bold', fontSize: 16, textTransform: 'capitalize', display: 'flex', justifyContent: 'space-between'}} gutterBottom variant="subtitle2" component="div">{formatPrice(trader.loss)}</Typography>                              
                            </Box>
                        </Box> 
                          
                          <Box sx={{ width: '100%' }}>
                          <LinearProgress variant="determinate" value={calcPercentage(trader.profit, trader.loss)}  />
                          </Box>
                        </CardContent>
                      </Card>


                      {/* <Card sx={{borderRadius: '9px', height: '100%', marginY: '5px'}}>
                          <CardContent>
                              <List
                              subheader={<ListSubheader sx={{zIndex: 0, position: 'relative'}}>Account Details</ListSubheader>}
                              >
                                  <ListItem disablePadding>
                                      <ListItemButton>
                                      <ListItemIcon>
                                          <AccountBalanceWalletIcon />
                                      </ListItemIcon>
                                      <ListItemText primary={formatPrice(trader.floating_profit)} secondary='Floating Profit' />
                                      </ListItemButton>
                                  </ListItem>
                                  <ListItem disablePadding>
                                      <ListItemButton>
                                      <ListItemIcon>
                                          <AccountBalanceWalletIcon />
                                      </ListItemIcon>
                                      <ListItemText primary={formatPrice(trader.balance)} secondary='Balance' />
                                      </ListItemButton>
                                  </ListItem>

                                  <ListItem disablePadding>
                                      <ListItemButton>
                                      <ListItemIcon>
                                          <AccountBalanceWalletIcon />
                                      </ListItemIcon>
                                      <ListItemText primary={formatPrice(trader.master_traders_bonus)} secondary='Master Trader Bonus' />
                                      </ListItemButton>
                                  </ListItem>

                                  <ListItem disablePadding>
                                      <ListItemButton>
                                      <ListItemIcon>
                                          <CreditScoreIcon />
                                      </ListItemIcon>
                                      <ListItemText primary={trader.leverage} secondary='Leverage' />
                                      </ListItemButton>
                                  </ListItem>
                              </List>
                          </CardContent>
                      </Card> */}

                      {/* <Card sx={{borderRadius: '9px', height: '100%', marginY: '5px'}}>
                        <CardContent>
                            <List
                            subheader={<ListSubheader sx={{zIndex: 0, position: 'relative'}}>Risk Management</ListSubheader>}
                            >
                                <ListItem disablePadding>
                                    <ListItemButton>
                                    <ListItemIcon>
                                        <AccountBalanceWalletIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={formatPrice(trader.max_unrealised_loss)} secondary='Maximum Unrealised Loss' />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton>
                                    <ListItemIcon>
                                        <AccountBalanceWalletIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={`${trader.max_drawdown_duration}d`} secondary='Maximum Drawdown Duration' />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </CardContent>
                    </Card> */}
                      {/* summary content stops here */}
                    </CustomTabPanel>

                    <CustomTabPanel value={value} index={1}>
                      {trader.trades.length > 0 ?
                      trader.trades.map(trade=>(

                        
                        <Accordion sx={{borderRadius: '10px', marginBottom: '10px'}} >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Box sx={{display: 'flex', justifyContent: 'space-between', width: '95%', alignItems: 'center'}}>
                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                              <Box mb={2} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                                <Chip label={trade.trade_type} sx={{fontSize: '10px'}} size="small" color="success" /> 
                                  <Typography ml={1} my={0} variant="caption" color="text.secondary">
                                  {trade.order_id}
                                  </Typography>
                              </Box>
                              <Typography sx={{fontWeight: 'bolder'}}>{trade.currency}</Typography>
                            </Box>

                            <Box  sx={{display: 'flex', flexDirection: 'column'}}>

                              <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                                  <LockClockIcon /> 
                                  <Typography py={1} ml={1} variant="caption">{trade.status}</Typography>
                              </Box>
                              {trade.amount > 0 ?
                                <Typography sx={{color: '#19ff01', fontWeight: 'bolder'}}>${trade.amount}</Typography> 
                               :
                                <Typography sx={{color: '#ff1414', fontWeight: 'bolder'}}>${trade.amount}</Typography>
                                }
                              
                            </Box>
                          </Box>
                        </AccordionSummary>

                        <AccordionDetails>
                          <Divider/>

                          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                            <Typography py={1} variant="caption" >Open Time</Typography>
                            <Typography py={1} variant="caption" >{getDate(trade.open_time)}</Typography>
                          </Box>
                          <Divider/>

                          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                            <Typography py={1} variant="caption" >Close Time</Typography>
                            
                            <Typography py={1} variant="caption" >{getDate(trade.close_time)}</Typography>
                          </Box>
                          <Divider/>

                          {/* <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                            <Typography py={1} variant="caption" >Open Price</Typography>
                            
                            <Typography py={1} variant="caption" >{trade.open_price}</Typography>
                          </Box>
                          <Divider/> */}

                          {/* <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                            <Typography py={1} variant="caption" >Close Price</Typography>
                            
                            <Typography py={1} variant="caption" >{trade.close_price}</Typography>
                          </Box>
                          <Divider/> */}

                          {/* <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                            <Typography py={1} variant="caption" >Order ID</Typography>
                            
                            <Typography py={1} variant="caption" >#{trade.order_id}</Typography>
                          </Box>
                          <Divider/> */}

                          {/* <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                            <Typography py={1} variant="caption" >Commission You Paid</Typography>
                            
                            <Typography py={1} variant="caption" >${trade.commission}</Typography>
                          </Box> */}

                              <AdvancedRealTimeChart
                                symbol= {`FX:${trade.currency}`}
                                interval= "1"
                                timezone= "Etc/UTC"
                                theme= {appMode}
                                locale= "en"
                                hide_top_toolbar= {true}
                                enable_publishing= {false}
                                save_image={false}
                                hide_side_toolbar={true}
                                container_id= {trade.id}
                                isTransparent = {true}
                                height={400}
                                width="100%"
                              >
                               </AdvancedRealTimeChart>
                          {appMode === 'dark'? 
                          <Box sx={{height: '20px', backgroundColor: 'black', transform: 'translate(0px, -25px)', position: 'relative', zIndex: 10}}>
                          
                          </Box>
                          :
                          <Box sx={{height: '20px', backgroundColor: 'white', transform: 'translate(0px, -25px)', position: 'relative', zIndex: 10}}>
                          
                          </Box>
                          }
                          

                        
                        </AccordionDetails>
                      </Accordion>
                      ))                      
                      :
                      <Alert severity="info">No records yet!</Alert>
                      }
                      
                    </CustomTabPanel>
                  </Box>
              </Grid>
          </Grid>
        </Fragment>

      ))}



       
<Dialog
        
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle sx={{textAlign: 'center'}}>{"FUND YOUR WALLET"}</DialogTitle> */}

        <DialogContent>

          <DialogContentText sx={{textAlign: 'center'}} mb={2} id="alert-dialog-slide-description">
          Kindly fill the form below to get started
          </DialogContentText>

          <Box sx={{display: 'flex',  justifyContent: 'center', flexDirection: 'column'}}>


                        <FormControl sx={{ m: 1, minWidth: '100%' }} size="small">
                            <InputLabel htmlFor="proportion">Copy Proportion</InputLabel>
                            {(error !== '' && error.duration) ?
                            <OutlinedInput
                            error
                            value={proportion}
                            onChange={(e) => {setAmount(e.target.value * selectedtrader.min_amount); setProportion(e.target.value)}}
                            labelId="proportion"
                            id="proportion"
                            name="proportion"
                            type={'number'}
                            label={`Copy Proportion`}
                            helperText={error.proportion}
                            />
                          
                            :
                            <OutlinedInput
                                onChange={(e) => {setAmount(e.target.value * selectedtrader.min_amount); setProportion(e.target.value)}}
                                value={proportion}
                                type={'number'}
                                labelId="proportion"
                                name="proportion"
                                id="proportion"
                                label={`Copy Proportion`}
                                />
                            }

                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.duration}
                            </Typography>
                        </FormControl>
                        
                        <Box sx={{display: 'flex', justifyContent: 'space-evenly', paddingX: '12px', marginBottom: '10px'}}>
                        <Chip label="1X" onClick={()=>{setAmount(selectedtrader.min_amount); setProportion(1)}} />
                        <Chip label="2X" onClick={()=>{setAmount(selectedtrader.min_amount * 2); setProportion(2)}} />
                        <Chip label="3X" onClick={()=>{setAmount(selectedtrader.min_amount * 3); setProportion(3)}} />
                        <Chip label="4X" onClick={()=>{setAmount(selectedtrader.min_amount * 4); setProportion(4)}} />
                        <Chip label="5X" onClick={()=>{setAmount(selectedtrader.min_amount * 5); setProportion(5)}} />
                        </Box>

                        <FormControl  sx={{ m: 1, minWidth: '100%' }} size="small">
                          <InputLabel htmlFor="amount">Amount To Pay</InputLabel>
                          <OutlinedInput
                            readOnly
                            value={amount}
                            onChange={(e)=>setAmount(e.target.value)}
                            id="amount"
                            type={'number'}
                            label="Amount To Pay"
                          />
                        </FormControl>
                      
                      <FormControl sx={{ m: 1, minWidth: '100%' }} size="small">
                          <InputLabel htmlFor="source">Select Payment Source</InputLabel>
                          {(error !== '' && error.source) ?
                          <Select
                          error
                          onChange={(e) => 
                                
                            {
                              setSource(e.target.value)
                              getCoinDetails(e.target.value)
                            }}
                          labelId="source"
                          id="source"
                          name="source"
                          label={`Select Payment Source`}
                          helperText={error.source}
                          >
                          <MenuItem selected value=''>
                          Select Gateway
                          </MenuItem> 

                              <MenuItem selected value="Overall Asset">
                              Overall Asset (Bal = {formatPrice(fundingBal)})
                              </MenuItem>
                              {/* {addresses.length > 0 && 
                              addresses.map((coin) =>(
                                coin.display_area === 'payments' &&
                                <MenuItem key={coin.coin_name} selected value={coin.coin_name}>
                                  {coin.coin_name} 
                                </MenuItem> 
                              ))}  */}
                          
                      </Select>
                          :
                          <Select
                          onChange={(e) => 
                                
                            {
                              setSource(e.target.value)
                              getCoinDetails(e.target.value)
                            }}
                              labelId="source"
                              name="source"
                              id="source"
                              label={`Select Payment Source`}
                              >
                              <MenuItem selected value=''>
                              Select Gateway
                              </MenuItem> 

                              <MenuItem selected value="Overall Asset">
                              Overall Asset (Bal = {formatPrice(fundingBal)})
                              </MenuItem>
                              {/* {addresses.length > 0 && 
                              addresses.map((coin) =>(
                                coin.display_area === 'payments' &&
                                <MenuItem key={coin.coin_name} selected value={coin.coin_name}>
                                  {coin.coin_name} 
                                </MenuItem> 
                              ))}  */}
                          </Select>
                          }
                          <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                          {error !== '' && error.source}
                          </Typography>
                      </FormControl>

                      {selectedCoin !== null &&
                      
                      <Fragment>
                        
                      <Alert severity="info">

                      {selectedCoin.wallet_type === 'bank' ?
                        `Kindly make payment to our ${selectedCoin.coin_name} account using the details below`
                        : 
                        `Kindly send ${formatPrice(amount)} worth of ${selectedCoin.coin_name} to the provided address below.`
                      }
                        
                        
                        
                        </Alert>    

                        {selectedCoin.wallet_type === 'Crypto' &&                   
                      <Box my={2} sx={{display: 'flex', justifyContent: 'center' }}>
                        <QRCode qrStyle="dots" value={selectedCoin.address} size={200}
                        logoImage={``}
                      />
                      </Box>
                      }

                            {/* <Box my={2} sx={{display: 'flex', justifyContent: 'center' }}>
                              <CircularProgress />           
                            </Box> */}
                          <Box my={2} sx={{display: 'flex', justifyContent: 'center' }}>
                          <CopyToClipboard onCopy={() => {setbuttonText(selectedCoin.wallet_type === 'bank'? 'Account Details Copied' : 'Address Copied');
                              setTimeout(() => {
                                  setbuttonText(null);
                              }, 2000);}} text={selectedCoin.address}>
                              <FormControl  fullWidth={true} sx={{ minWidth: 50, marginBottom: 2 }}>
                                  <TextField
                                  minRows={1} multiline
                                  
                                  defaultValue={selectedCoin.address}
                                  id="address"
                                  name="address"
                                  type={'text'}
                                  label= {buttonText !== null? buttonText: `Click to copy ${selectedCoin.coin_name} ${selectedCoin.wallet_type === 'bank'? 'bank details' : 'address'} address`}
                                  />
                              </FormControl> 
                          </CopyToClipboard>
                          </Box>
                      </Fragment>
                      }
          </Box> 

          <Button onClick={postInvestment} fullWidth={true} size="large" color="primary" variant="contained"> 
            {isInvesting ? 'Sending...' : 'Start Copying'}  
          </Button>      
          
        </DialogContent>
        
      </Dialog>
    
    </div>
  )
}

export default TraderSingle
