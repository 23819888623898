import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import { Container, Card, CardContent } from '@mui/material';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import AssetsSlide from './AssetsSlide';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { Link as RouterLink } from 'react-router-dom';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import InsertChartIcon from '@mui/icons-material/InsertChart';

export default function SearchButton() {
  return (
    <Container sx={{marginTop: '5px'}}>
        <Card>
    <Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
    >
      <IconButton component={RouterLink} to="/start-trading" color="primary" sx={{ p: '10px' }} aria-label="menu">
        <InsertChartIcon/>
      </IconButton>
      
      <AssetsSlide/>
      {/* <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton> */}
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton component={RouterLink} to="/account-wallet" color="primary" sx={{ p: '10px' }} aria-label="directions">
        <AccountBalanceWalletIcon />
      </IconButton>
    </Paper>
    </Card>
    
    </Container>
  );
}
