import React, {useState, useEffect} from 'react'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Slider from "react-slick";
import { Link as RouterLink } from 'react-router-dom';
import Wave from 'react-wavify';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import { Button, CardActions, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ListItemButton from '@mui/material/ListItemButton';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SavingsIcon from '@mui/icons-material/Savings';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import apiClient from "../../request/http-common";
import { useMutation } from "react-query";
import { useNavigate } from 'react-router-dom';
import { setInvestments } from '../../request/Analysis/hitorySlice';
import { useDispatch } from 'react-redux';
import { Currency } from '@depay/local-currency';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Link } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InvestmentPlans = () => {
  const settings = {
    autoplay: true,
    pauseOnHover: true,
    dots: false,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    vertical: true,
    arrows: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          vertical: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          vertical: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const coinRates = useSelector((state) => state.coins.coinRates);
  const fundings = useSelector((state) => state.wallet.fundings);
  const tradings = useSelector((state) => state.history.tradings);
  
  const calculateBalance = (coin) => {
    let amount = 0;
    fundings.forEach(fund => {
      if (fund.gateway === coin) {
        amount += parseFloat(fund.amount);
      }
      });

      tradings.forEach(trade => {
        if (trade.pair === coin) {
          amount += parseFloat(trade.profit);
        }
        });

    
      return amount;
  }

  const [open, setOpen] = React.useState(false);
  const [amount, setAmount] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const navigate = useNavigate();
  const plans = useSelector((state) => state.plans.plans);
  const user = useSelector((state) => state.user.user);

    const fundingBal = useSelector((state) => state.wallet.fundingBalance);
    const subBal = useSelector((state) => state.wallet.subscriptionBalance);
    const refBal = useSelector((state) => state.wallet.referralBalance);
    const mainBal = useSelector((state) => state.wallet.withdrawableBalance);
  
  const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

  const dispatch = useDispatch();
  const [rate, setRate] = useState(1);

  const callRate = async()=> {
    if(user.currency === 'USD'){
      setRate(1);
    }else{
      await Currency.fromUSD({ amount: 1 }).then(amount=>{
        setRate(amount.amount);
       });
    }  
};


    const setcategories = (number_of_downlines, total_earning, initial_earning) => {
        const categories = [];
        for (let i = 0; i <= parseInt(number_of_downlines); i++) {
            let heads = '';
            for (let j = 1; j <= i; j++) {
                heads += '🙂';
            }

            if (i === 0) {
              const initialPercentage = (parseInt(initial_earning) / parseInt(total_earning))*100;
                categories.push('INITIAL ROI = '+initialPercentage.toFixed(2)+'%');
            }else if (i === parseInt(number_of_downlines)) {
                categories.push('STAGE '+i +' = ' + heads+ '' );
            } else {
                categories.push('STAGE '+i +' = ' + heads);
            }
            
        }
        return categories;
    }

    const setAmounts = (initial_earning, total_earning, number_of_downlines, bonus_per_downline, amount_invested) => {

      const initialPercentage = (parseInt(initial_earning) / parseInt(total_earning))*100;
      const amounts = [initialPercentage.toFixed(2)];
      
      for (let downlines = 1; downlines <= parseInt(number_of_downlines); downlines++) {
        amounts.push(parseInt(bonus_per_downline * downlines))
      }
        

        return amounts;

    }

    const [planId, setPlanId] = useState();
    const [selectedPlan, setSelectedPlan] = useState('');
    const [source, setSource] = useState('');
    const [currentDuration, setCurrentDuration] = useState('');
    const [convertedAmount, setConvertedAmount] = useState(0);
    const [error, setErros] = useState('');

    const { isLoading: isInvesting, mutate: postInvestment } = useMutation(
        async () => {
          return await apiClient.post(`/api/invest`, {
            investment_plan_id: planId,
            amount: amount, 
            rate: rate,
            duration: currentDuration,
            payment_source: source
          });
        },
        {
          onSuccess: (res) => {
              if (res.data.status === 'success') {
                localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
                dispatch(setInvestments(res.data.investments));
                setTimeout(() => {
                  navigate('/investments');
                }, 2000);
              }            
          },
          onError: (err) => {
                // let errorMessage = err.response?.data.message || err ;
                // localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
                // const event = new Event('newMessage');
                // window.dispatchEvent(event);

                if (err.response?.data.length) {
                  let myerror = err.response?.data || err;         
              setErros(myerror.errors)               
              }else{
                  let errorMessage = err.response?.data.message || err ;
                  localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
                  const event = new Event('newMessage');
                  window.dispatchEvent(event);
              }
          },
        }
      );

      
      useEffect(() => {
        callRate();
      }, []);

  return (
    <div>
   
      {plans.length > 0 &&
      <Card>
      <Slider {...settings}>
      {plans.map(plan =>(
        <Link bgcolor="inherit" key={plan.id} to={`/package/${plan.category.name}`} component={RouterLink} underline="none" color="inherit">
        
        <Stack sx={{margin: '3px', marginX: '6px', backgroundColor: 'background.paper',  paddingX: '2px'}} elevation={4}>
       
        <Box sx={{display: 'flex', justifyContent:  'space-evenly', padding: '8px'}}>  

          <Box>
            <Typography gutterBottom variant="h6" sx={{textAlign: 'left'}}>
            {plan.name}
            </Typography>


            <Box>
                <Typography variant='subtitle2' sx={{textAlign: 'left'}}>
                {formatPrice(plan.min_amount )} - { parseInt(plan.amount_invested) >= 1000000 ? 'UNLIMITED' : formatPrice(plan.amount_invested )}
                </Typography>

                <Typography sx={{textAlign: 'left', color: '#0af21d'}} variant="subtitle1" gutterBottom>
                {`${parseFloat(plan.total_earning).toFixed(2)}%`} ROI
                </Typography>
            </Box>

            <Box sx={{textAlign: 'left'}}>
              <Button onClick={()=> {
                handleClickOpen();
                  setPlanId(plan.id);
                  setSelectedPlan(plan);
                  setConvertedAmount(parseFloat(plan.amount_invested ))                

              }}  fullWidth={false} color='primary' size="small" sx={{ textTransform: 'capitalize' }} variant="contained">
                  {(isInvesting && planId === plan.id) ? 'Sending....' : 'Stake'}
              </Button>
              </Box>


          </Box>

        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <Box sx={{width: '100px'}}>
        <ReactApexChart options={{
          chart: {
            type: 'pie',
          },
          colors: ['#f8a500', '#0d9153'],
          legend: {
            show: false,
            showForSingleSeries: false,
            showForNullSeries: false,
            showForZeroSeries: false,
            position: 'middle'
          },
          
          labels: ['ROI', ''],
          dataLabels: {
            offsetX: 0,
            offsetY: 0,
            textAnchor: 'start',
            formatter(val, opts) {
              const name = opts.w.globals.labels[opts.seriesIndex]
              if (val == parseFloat(plan.total_earning)) {
                return [name, val.toFixed(1) + '%']
              }else{
                return ['']
              }
              
            },
            
          },
          plotOptions: {
            pie: {
              dataLabels: {
                offset: -20
              }
            }
          }
          ,
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              
            }
          }]
        }}
        series={[parseFloat(plan.total_earning), parseFloat(100 - plan.total_earning)]}
        type="pie" height={90}
        />
        </Box>
        

        </Box>

        </Box>
        

    </Stack>
    </Link>
    
      ))
      
    }
    </Slider>
    </Card>
      }


      
              
      <Dialog
        
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle sx={{textAlign: 'center'}}>{"FUND YOUR WALLET"}</DialogTitle> */}

        <DialogContent>

          <DialogContentText sx={{textAlign: 'center'}} mb={2} id="alert-dialog-slide-description">
          Kindly enter any amount from {selectedPlan !== '' &&`
           ${formatPrice(selectedPlan.min_amount )} to  ${formatPrice(selectedPlan.amount_invested )} 
          `        
          }   to invest
            {/* {istracking ? 'Tracking payment..., you will receive email and your account will be credited once your payment is confirmed. Thank you.': 

            `Kindly enter any amount in ${Currency.getCode()} and proceed to pay`
            } */}


            
          </DialogContentText>

          <Box px={1} sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>


            <FormControl  sx={{ m: 1, minWidth: '100%' }} size="small">
              <InputLabel htmlFor="amount">Enter Amount</InputLabel>
              <OutlinedInput
                onChange={(e)=>setAmount(e.target.value)}
                id="amount"
                type={'number'}
                label="Enter Amount"
              />
            </FormControl>

            {(selectedPlan !== '' && selectedPlan.duration.split(',').length > 0 )&&
                        <FormControl sx={{ m: 1, minWidth: '100%' }} size="small">
                        <InputLabel htmlFor="duration">Select duration</InputLabel>
                        {(error !== '' && error.duration) ?
                        <Select
                        error
                        onChange={(e) => setCurrentDuration(e.target.value)}
                        labelId="duration"
                        id="duration"
                        name="duration"
                        label={`Select duration`}
                        helperText={error.duration}
                        >
                        <MenuItem selected value=''>
                            Select duration
                        </MenuItem> 
                        {selectedPlan.duration.split(',').map(duration =>(
                            <MenuItem value={duration}>{duration}</MenuItem>
                        ))}             
                        
                    </Select>
                        :
                        <Select
                            onChange={(e) => setCurrentDuration(e.target.value)}
                            labelId="duration"
                            name="duration"
                            id="duration"
                            label={`Select duration`}
                            >
                            <MenuItem selected value="">
                                Select duration
                            </MenuItem>              
                            {selectedPlan.duration.split(',').map(duration =>(
                            <MenuItem value={duration}>{duration}</MenuItem>
                            ))}  
                        </Select>
                        }
                        <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        {error !== '' && error.duration}
                        </Typography>
                    </FormControl>
                        }


                      <FormControl sx={{ m: 1, minWidth: '100%' }} size="small">
                          <InputLabel htmlFor="source">Select Payment Source</InputLabel>
                          {(error !== '' && error.duration) ?
                          <Select
                          error
                          onChange={(e) => setSource(e.target.value)}
                          labelId="source"
                          id="source"
                          name="source"
                          label={`Select Payment Source`}
                          helperText={error.duration}
                          >
                          <MenuItem selected value=''>
                          Select Gateway
                          </MenuItem> 

                          <MenuItem selected value="Withdrawable Balance">
                          Spot Wallet (Bal = {formatPrice(mainBal)})
                          </MenuItem>

                          <MenuItem selected value="Funding Wallet">
                          Funding Wallet (Bal = {formatPrice(fundingBal)})
                          </MenuItem>

                          <MenuItem selected value="Referral Wallet">
                          Referral Wallet (Bal = {formatPrice(refBal)})
                          </MenuItem>             
                          
                      </Select>
                          :
                          <Select
                              onChange={(e) => setSource(e.target.value)}
                              labelId="source"
                              name="source"
                              id="source"
                              label={`Select Payment Source`}
                              >
                              <MenuItem selected value=''>
                              Select Gateway
                              </MenuItem> 

                              <MenuItem selected value="Withdrawable Balance">
                              Spot Wallet (Bal = {formatPrice(mainBal)})
                              </MenuItem>
                              <MenuItem selected value="Referral Wallet">
                              Referral Wallet (Bal = {formatPrice(refBal)})
                              </MenuItem> 

                              {coinRates.length > 0 && 
                              coinRates.map((coin) =>(
                                <MenuItem key={coin.symbol} selected value={coin.symbol}>
                                  {coin.symbol} (bal = {formatPrice(calculateBalance(coin.symbol))}) 
                                </MenuItem> 
                              ))} 

                              {/* <MenuItem selected value="Funding Wallet">
                              Funding Wallet (Bal = {formatPrice(fundingBal)})
                              </MenuItem> */}

                              
                          </Select>
                          }
                          <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                          {error !== '' && error.duration}
                          </Typography>
                      </FormControl>
          </Box> 

          <Button onClick={postInvestment} fullWidth={true} size="large" color="primary" variant="contained"> 
            {isInvesting ? 'Sending...' : 'Submit Request'}  
          </Button>      
          
        </DialogContent>
        
      </Dialog>
    
</div>
  )
}

export default InvestmentPlans
