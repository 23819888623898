import React, {useState, useEffect, Fragment} from 'react';
import Slider from "react-slick";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import { useSelector } from 'react-redux';
import ReactCardFlip from 'react-card-flip';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { Divider } from '@mui/material';
import WalletButton from '../Wallet/WalletButton';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const BalanceCard = () => {
  
  const user = useSelector((state) => state.user.user);
  const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);
  const fundingBal = useSelector((state) => state.wallet.fundingBalance);
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const coins = useSelector((state) => state.coins.coins);
  const mainBal = useSelector((state) => state.wallet.withdrawableBalance);

  const [isFlipped, setFlipped] = useState(false);
    const [crdType, setCardType] = useState('');

    const getBTCbalance = () => {
      let bal = 0;
      Object.keys(coins).forEach(coin => {
        if (coins[coin].symbol === 'BTC') {
          bal = (mainBal/coins[coin].price).toFixed(8) ;
        }
      });

      return bal;
    }

  const handleClick = (e) => {
      e.preventDefault();
      setFlipped(!isFlipped);
    }

    const initialCards = {
        number1: 'XXXX',
        number2: 'XXXX',
        number3: 'XXXX',
        number4: 'XXXX',
        name: 'XXXXX XXXXXX',
        expiry: 'XX/XX',
        cvv: 'XXX',
        type: 'visa'
    }

    const updateCradDetails = (card) => {
        setCurrentCard({...currentCard, 
            number1: card.number1,
            number2: card.number2,
            number3: card.number3,
            number4: card.number4,
            name: user.name,
            expiry: '08/2029',
            cvv: '745',
            type: card.card_type
        })
    }

    const [currentCard, setCurrentCard] = useState(initialCards);
    const getDate = (date) => new Date(date).toDateString();

    useEffect(() => {

      setTimeout(() => {
        setFlipped(true);
      }, 3000);

      setTimeout(() => {
        setFlipped(false);
      }, 5000);


    }, []);

  return (
    <div >
        <Card sx={{ paddingX: '10px', marginTop: '20px'}} elevation={0}>
          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Box sx={{display: 'flex', paddingX: '10px', justifyContent: 'flex-start', flexDirection: 'column'}}>
            <Typography variant="caption" gutterBottom sx={{ display: 'block', textAlign: 'left' }}>
              Total Assets
            </Typography>
            <Typography sx={{fontWeight: 'bolder', fontSize: 18, textAlign: 'left'}}  variant="h6">
                {formatPrice(mainBal)} (USD)
            </Typography>

            <Typography sx={{ fontSize: 18, textAlign: 'left'}}  variant="subtitle1" color="text.secondary">
                <Typography variant="caption" display="block" gutterBottom>
                {getBTCbalance()} BTC 
                </Typography>
            </Typography>
            </Box>

            <Box>
            <WalletButton/>
            </Box>
          </Box>
        </Card>
        <Divider/>

    </div>
  )
}

export default BalanceCard
