import React, { useEffect, useState } from "react";
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow, TableContainer as MUITableContainer } from "@mui/material";
import { motion } from "framer-motion";

const generateOrders = (size, c_price) => {
  return Array.from({ length: size }, () => ({
    price: (c_price + Math.random() * 1).toFixed(2),
    amount: (Math.random() * 5).toFixed(4),
  })).sort((a, b) => b.price - a.price);
};

const OrderBook = (props) => {
const {price} = props;
  const [bids, setBids] = useState(generateOrders(10, price));
  const [asks, setAsks] = useState(generateOrders(10, price));

  useEffect(() => {
    const interval = setInterval(() => {
      setBids(generateOrders(10, price));
      setAsks(generateOrders(10, price));
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", p: 0, position: "relative" }}>
      <Box sx={{ display: "flex", gap: 0, mt: 2, position: "relative", width: "100%" }}>
        <TableContainer title="Bids" data={bids} color="green" direction="left" />

        <TableContainer title="Asks" data={asks} color="red" direction="right" />
      </Box>
    </Box>
  );
};

const TableContainer = ({ title, data, color, direction }) => {
  const maxAmount = Math.max(...data.map((order) => parseFloat(order.amount)));

  return (
    <Box sx={{
      position: "relative",
      overflow: "hidden",
      width: "50%",
      animation: `moveBackground 5s linear infinite`,
      animationDirection: direction === "left" ? "normal" : "reverse",
    }}>
      <Typography variant="subtitle1" sx={{ color }}>{title}</Typography>
      {title === 'Bids' ?
      <MUITableContainer sx={{maxHeight: 'none', border: 'none'}}>
      <Table size="small" sx={{ position: "relative", border: 'none' }}>
        <TableHead>
          <TableRow  sx={{ borderBottom: "none" }}>
            <TableCell sx={{borderBottom: "none"}}>Amount</TableCell>
            <TableCell sx={{borderBottom: "none"}}>Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{border: 'none'}}>
          {data.map((order, index) => (
            <motion.tr
              key={index}
              initial={{ opacity: 0, x: -10 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
              style={{ position: "relative" }}
            >
            <TableCell sx={{borderBottom: "none"}}>{order.amount}</TableCell>
              <TableCell sx={{ position: "relative", padding: 0, borderBottom: "none" }}>
                <Box sx={{ position: "relative",  padding: "8px", color: '#38ba38' }}>
                    {order.price}
                </Box>
              </TableCell>
              
              {title === 'Bids' &&
                  <Box
                  sx={{
                    position: "absolute",
                    right: 0,
                    width: `${(parseFloat(order.amount) / maxAmount) * 100}%`,
                    height: "100%",
                    backgroundColor: color,
                    opacity: 0.2,
                    transition: "width 0.5s ease-in-out",
                  }}
                />

                  }
            </motion.tr>
          ))}
        </TableBody>
      </Table>
    </MUITableContainer>
      
      :
      <MUITableContainer sx={{maxHeight: 'none', border: 'none'}}>
        <Table size="small" sx={{ position: "relative", border: 'none' }}>
          <TableHead>
            <TableRow  sx={{ borderBottom: "none" }}>
              <TableCell sx={{borderBottom: "none"}}>Price</TableCell>
              <TableCell sx={{borderBottom: "none"}}>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{border: 'none'}}>
            {data.map((order, index) => (
              <motion.tr
                key={index}
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
                style={{ position: "relative" }}
              >
                <TableCell sx={{ position: "relative", padding: 0, borderBottom: "none" }}>
                    {title === 'Asks' &&
                    <Box
                    sx={{
                    position: "absolute",
                    left: 0,
                    width: `${(parseFloat(order.amount) / maxAmount) * 100}%`,
                    height: "100%",
                    backgroundColor: color,
                    opacity: 0.2,
                    transition: "width 0.5s ease-in-out",
                    }}
                    />}
                  <Box sx={{ position: "relative", padding: "8px", color: '#f52f2f' }}>{order.price}</Box>
                </TableCell>
                <TableCell sx={{borderBottom: "none"}}>{order.amount}</TableCell>
                {title === 'Bids' &&
                    <Box
                    sx={{
                      position: "absolute",
                      right: 0,
                      width: `${(parseFloat(order.amount) / maxAmount) * 100}%`,
                      height: "100%",
                      backgroundColor: color,
                      opacity: 0.2,
                      transition: "width 0.5s ease-in-out",
                    }}
                  />

                    }
              </motion.tr>
            ))}
          </TableBody>
        </Table>
      </MUITableContainer>
      }
      
    </Box>
  );
};

export default OrderBook;
