import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CoinMarket from '../Home/CoinMarket';
import Favorites from './Favorites';
import Gainers from './Gainers';
import Losers from './Losers';

export default function MarketTabs() {
  
  const [value, setValue] = React.useState('Favorites');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example" 
          variant="scrollable"
          gap={1}
          scrollButtons={true}
          >
            <Tab label="Favorites" value="Favorites" />            
            <Tab label="Gainers" value="Gainers" />
            <Tab label="Losers" value="Losers" />
            <Tab label="Hot" value="Hot" />
            <Tab label="New" value="New" />
            <Tab label="Turnover" value="Turnover" />
          </TabList>
        </Box>

        <TabPanel value="Favorites"><Favorites/></TabPanel>
        <TabPanel value="Gainers"><Gainers/></TabPanel>
        <TabPanel value="Losers"><Losers/></TabPanel>
        <TabPanel value="Hot"><Gainers/></TabPanel>
        <TabPanel value="New"><Favorites/></TabPanel>
        
        <TabPanel value="Turnover">Turnover</TabPanel>

      </TabContext>
    </Box>
  );
}
