import React, {useEffect, useState} from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useQuery } from 'react-query';
import apiClient from '../../request/http-common';
import { Fragment } from 'react';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setcurrentPair } from '../Trader/tradingSlice';
import { useNavigate } from 'react-router-dom';

const Losers = () => {

    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);
    const coins = useSelector((state) => state.coins.coins);
    const coinRates = useSelector((state) => state.coins.coinRates);

    const [coinSymbol, setCoinSymbol] = useState('BINANCE:BTCUSDT');
    const availableCoins = ['XMR', 'USDT', 'USDTERC20', 'XLM', 'ETH', 'TRX', 'XRP', 'BTC', 'LTC', 'BCH', 'DOGE', 'DASH', 'BNB'];
    const fundings = useSelector((state) => state.wallet.fundings);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const tradings = useSelector((state) => state.history.tradings);
    const investments = useSelector((state) => state.history.investments);
    const withdrawals = useSelector((state) => state.history.withdrawals);

    const calculate24hrChange = (coin) => {
      let rate = 0;
      coinRates.forEach(coinRater => {
        if (coinRater.symbol === coin) {
          rate = parseFloat(coinRater.changePercent24Hr);
        }
      });

      return rate;
    }

    const calculateBalance = (coin) => coin.balance === null ? 0 : coin.balance
    
  return (
    <div>

        {coinRates.length < 1 &&
        <CircularProgress/>
        }
        {coinRates.length > 0 && 

        <List sx={{ width: '100%', bgcolor: 'background.paper'}}>

            {coinRates.map((coin) =>(
              calculate24hrChange(coin.symbol) < 0 &&
                <Fragment key={coin.symbol}>
                <ListItem alignItems="space-evenly" sx={{cursor: 'pointer', paddingX: 0, alignItems: 'center'}}
                onClick={()=>{
                  if (coin.symbol === 'USDT') {
                    dispatch(setcurrentPair('BTC'));
                  }else{
                    dispatch(setcurrentPair(coin.symbol));
                  }
                  
                  setTimeout(() => {
                    navigate('/trade');
                  }, 100);
              }}
                >
          <ListItemAvatar>
          
            <Avatar alt="Coin Name"
            src={`https://assets.coincap.io/assets/icons/${coin.symbol.toLowerCase()}@2x.png`}
            />
        </ListItemAvatar>
                <ListItemText
                  primary={coin.symbol}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline', fontSize: '11px' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {coin.name}
                      </Typography>
                    </React.Fragment>
                  }
                />

                  

                <Box edge="end" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignContent: 'flex-start', alignItems: 'flex-start'}}>
                    <Typography mx={1}>
                    {formatPrice(coin.priceUsd)}
                    </Typography>

                    {/* <Typography mx={1} sx={{display: 'flex', justifyContent: 'space-between'}}>
                    
                    <Typography style={{fontWeight: 'lighter', color: `${calculate24hrChange(coin.symbol) > 0 ? '#13e576' : '#e8073c'}`}} variant="caption" display="block" gutterBottom>
                          {calculate24hrChange(coin.symbol).toFixed(2)}
                        </Typography>
                     <Typography style={{marginLeft: '2px'}} variant="caption" display="block" gutterBottom>
                          24hr
                        </Typography> 
                    </Typography> */}
                </Box>


                {/* <Box edge="end" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignContent: 'flex-start', alignItems: 'flex-start'}}>
                    <Typography mx={1} sx={{fontWeight: 'bolder'}}>
                    {formatPrice(calculateBalance(coin))}
                    </Typography>

                    <Typography mx={1} variant="caption">
                    {(calculateBalance(coin)/coin.priceUsd).toFixed(8)}
                    </Typography>
                </Box> */}

                <Box edge="end" sx={{marginTop: '10px'}}>
                    <Button style={{fontSize: '14px', fontWeight: 'bolder'}} color={calculate24hrChange(coin.symbol) > 0 ? 'success' : 'error'} variant="contained" size="small">
                   {calculate24hrChange(coin.symbol) > 0 && '+'} {calculate24hrChange(coin.symbol).toFixed(2)}% 
                    </Button>
                </Box>


              </ListItem>
              <Divider variant="inset" component="li" />
              </Fragment>
            ))}
          </List>
        
        }
      
  </div>
  )
}

export default Losers
