import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    readMessages: localStorage.getItem('readMessage') || '',
    messages: JSON.parse(localStorage.getItem('messages')) || [],
}

export const messagestSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {

        setMessages: (state, action) =>{
            state.messages = action.payload;  
            localStorage.setItem('breakfasts', JSON.stringify(state.messages));
        },
        
        setReadMessages: (state, action) => {
            state.readMessages = action.payload.id;
            localStorage.setItem('readMessage', state.readMessages);
            console.log(state.readMessages);
        }
    }
})

export const {setMessages, setReadMessages} = messagestSlice.actions;
export default messagestSlice.reducer;