import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Buy from './Buy';
import Sell from './Sell';
import BlogSlider from '../Blog/BlogSlider';

export default function P2P() {
  const [value, setValue] = React.useState('Buy');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1', marginTop: '75px' }}>
        
    <BlogSlider/>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="P2P Transaction" 
          variant="scrollable"
          gap={1}
          scrollButtons={true}
          >
            <Tab label="Buy" value="Buy" />            
            <Tab label="Sell" value="Sell" />
          </TabList>
        </Box>
        <TabPanel value="Buy"><Buy/></TabPanel>
        <TabPanel value="Sell"><Sell/></TabPanel>
        
      </TabContext>
    </Box>
  );
}
