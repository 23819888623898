import React from 'react'
import CoinMarket from '../Home/CoinMarket'
import WalletCards from './WalletCards'

import WalletButton from '../Wallet/WalletButton';
import WithdrawalButton from '../Withdrawal/WithdrawalButton';
import EarningAnalysis from '../Earning/EarningAnalysis';
import BalanceCard from '../Cards/BalanceCard';
import { CardActions, Card } from '@mui/material';
import Transactions from '../Transactions/Transactions';

const NewWallet = () => {
  return (
    <div style={{marginTop: '85px'}}>
     <BalanceCard/>    
    <Transactions/>
    </div>
  )
}

export default NewWallet
