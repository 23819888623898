import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../components/Auth/userSlice";
import modeReducer from "../components/Theme/modeSlice";
import walletReducer from "../components/Wallet/walletSlice";
import notificationReducer from "../components/Notifications/notificationSlice";
import redirectReducer from "../components/Auth/historySlice";
import plansReducer from "../request/Plans/planSlice";
import authReducer from "../request/Auth/authSlice";
import referralReducer from "../request/Referrals/referalSlice";
// import unitAnalysisReducer from "../components/Analysis/analysisSlice";
// import chartsReducer from "../components/Analysis/chartsSlice";
import coinReducer from "../request/CoinRates/coinSlice";
import historyReducer from "../request/Analysis/hitorySlice";
import earningRateReducer from "../request/Earning/rateSlice";
import currentPaiReducer from "../components/Trader/tradingSlice";
import tradersReducer from "../request/Traders/tradersSlice";
import messageReducer from "../components/Message/messageSlice";

export const store = configureStore({
    reducer: {
        user: userReducer,
        messages: messageReducer,
        mode: modeReducer,
        wallet: walletReducer,
        notifications: notificationReducer,
        redirect: redirectReducer,
        plans: plansReducer,
        auth: authReducer,
        referrals: referralReducer,
        // unitsAnalysis: unitAnalysisReducer,
        // chartsAnalysis: chartsReducer,
        coins: coinReducer,
        history: historyReducer,
        earning_rates: earningRateReducer,
        currentPair: currentPaiReducer,
        traders: tradersReducer


    }
})