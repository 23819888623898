import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';

const Search = styled('div')(({ theme }) => ({
  
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));


export default function Header() {
  const appMode = useSelector((state) => state.mode.mode);
  const notifications = useSelector((state) => state.notifications.notifications);

  const dispatch = useDispatch();

  
 

  const toggleSidebar = () => {
    const event = new Event("toggleSideBar");
    window.dispatchEvent(event);
  }
  const user = useSelector((state) => state.user.user);

  return (
    <Box  sx={{ flexGrow: 1, position: 'fixed', zIndex: 1500, width: '100%' }}>
      <AppBar position="static" color='custom' enableColorOnDark={false}>
        <Toolbar>
          {appMode === 'dark' ? 
          <Avatar sx={{width: 50, height: 50}}
           alt={user.name} src={'https://res.cloudinary.com/dqi4rtyvh/image/upload/v1739882805/whiterockyellow_czitz2.png'} />
          :
          <Avatar sx={{width: 58, height: 58}} alt={user.name} src={'https://res.cloudinary.com/dqi4rtyvh/image/upload/v1739882805/whiterockyellow_czitz2.png'} />

          }
         

          <Box sx={{ flexGrow: 1 }} />

          <ButtonGroup
            variant="contained"
            aria-label="Disabled button group"
          >
            <Button component={RouterLink} to="/" sx={{backgroundColor: '#5e5e5d', color: '#fff'}} size='small'>Exchange</Button>
            <Button component={RouterLink} to="/web3" sx={{backgroundColor: '#2e2d2c', color: '#fff'}} size='small'>WEB3</Button>
          </ButtonGroup>

          
          {/* <ButtonGroup
            variant="outlined"
            aria-label="Disabled button group"
          >
            <Button variant="contained" color='primary'>EXCHANGE</Button>
            <Button color='primary'>WEB3</Button>
          </ButtonGroup> */}
          
          <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
            

            <IconButton
              component={RouterLink} to="/notifications"
              size="large"
              aria-label="show notofocations"
              color="inherit"
            >
              <Badge badgeContent={notifications.length} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            
          </Box>
          

          <IconButton
            onClick={toggleSidebar}
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ ml: 2, display: {md: 'none'} }}
          >
            {/* <SupportAgentIcon /> */}
            <Avatar src='https://res.cloudinary.com/dqi4rtyvh/image/upload/v1739184208/menu-white_l9kiu6.png' sx={{ padding: '5px', width: 38, height: 38, bgcolor: 'primary', color: '#fff'}}>
            </Avatar> 
          </IconButton>

          {/* <IconButton
            onClick={toggleSidebar}
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ ml: 2, display: {md: 'none'} }}
          >
            <MenuIcon />
          </IconButton> */}
        </Toolbar>
      </AppBar>
    </Box>
  );
}