import * as React from 'react';
import { Fragment } from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import TelegramIcon from '@mui/icons-material/Telegram';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import GradingIcon from '@mui/icons-material/Grading';
import RuleIcon from '@mui/icons-material/Rule';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import TimelineIcon from '@mui/icons-material/Timeline';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import AddchartIcon from '@mui/icons-material/Addchart';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
// import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import Avatar from '@mui/material/Avatar';

export default function Online() {
  return (
    
    <Fragment>
          <List>
            <Link  to='/' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730471754/layout_holcz2.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#382f1b', color: '#fff'}}>
                  </Avatar> 
                  </ListItemIcon>
                  <ListItemText  primary={`Dashboard`} />
                  </ListItemButton>
              </ListItem>
            </Link>

           

            <Link  to='/assets' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1738068347/wallet_1_vmr3li.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#382f1b', color: '#fff'}}>
                  </Avatar> 
                  {/* <CurrencyExchangeIcon /> */}
                  </ListItemIcon>
                  <ListItemText  primary={`Assets`} />
                  </ListItemButton>
              </ListItem>
            </Link>


              
             
              <Link  to='/copy-plans' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1738068352/analytics_tjm1c0.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#382f1b', color: '#fff'}}>
                    </Avatar>
                    {/* <AddchartIcon /> */}
                    </ListItemIcon>
                    <ListItemText  primary={`Copy Trading`} />
                    </ListItemButton>
                </ListItem>
              </Link>

              <Link  to='/copy-history' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <Avatar src='https://res.cloudinary.com/dqi4rtyvh/image/upload/v1739919729/history_e6imr9.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#382f1b', color: '#fff'}}>
                  </Avatar>
                  {/* <CandlestickChartIcon /> */}
                  </ListItemIcon>
                  <ListItemText  primary={`Copy History`} />
                  </ListItemButton>
              </ListItem>
            </Link>


            <Link  to='/package/Trading Bots' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                    <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1738068352/robot_yozduf.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#382f1b', color: '#fff'}}>
                    {/* <AccountBalanceWalletIcon /> */}
                  </Avatar>
                  {/* <StackedBarChartIcon /> */}
                  </ListItemIcon>
                  <ListItemText  primary={`Trading Bots`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            
            <Link  to='/start-trading' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1738083863/candle_l9uey7.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#382f1b', color: '#fff'}}>
                    </Avatar>
                    {/* <AddchartIcon /> */}
                    </ListItemIcon>
                    <ListItemText  primary={`Trade`} />
                    </ListItemButton>
                </ListItem>
              </Link>

            <Link  to='/trade-history' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <Avatar src='https://res.cloudinary.com/dqi4rtyvh/image/upload/v1739919729/trade_qlzkn8.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#382f1b', color: '#fff'}}>
                  </Avatar>
                  {/* <CandlestickChartIcon /> */}
                  </ListItemIcon>
                  <ListItemText  primary={`Trade History`} />
                  </ListItemButton>
              </ListItem>
            </Link>
            

            <Link  to='/account-wallet' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <Avatar src='https://res.cloudinary.com/dqi4rtyvh/image/upload/v1738672665/cryptocurrency_i9vuqz.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#382f1b', color: '#fff'}}>
                    </Avatar>
                    {/* <AccountBalanceWalletIcon /> */}
                    </ListItemIcon>
                    <ListItemText  primary={`Transactions`} />
                    </ListItemButton>
                </ListItem>
              </Link>

              {/* <Link  to='/spot-transfer' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730472116/money_vn6pjz.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#382f1b', color: '#fff'}}>
                    </Avatar>
                    </ListItemIcon>
                    <ListItemText  primary={`Transfer`} />
                    </ListItemButton>
                </ListItem>
              </Link> */}

            <Link  to='/send-coin' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <Avatar src='https://res.cloudinary.com/dqi4rtyvh/image/upload/v1738590761/withdraw_eqdznq.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#382f1b', color: '#fff'}}>
                  </Avatar> 
                  </ListItemIcon>
                  <ListItemText  primary={`Withdraw`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            

              <Link  to='/investments' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <Avatar src='https://res.cloudinary.com/dqi4rtyvh/image/upload/v1739919729/profits_atawbu.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#382f1b', color: '#fff'}}>
                  </Avatar> 
                  {/* <StackedBarChartIcon /> */}
                  </ListItemIcon>
                  <ListItemText  primary={`Investments`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/swap-coin' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <Avatar src='https://res.cloudinary.com/dqi4rtyvh/image/upload/v1739863653/swap_vw5hqj.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#382f1b', color: '#fff'}}>
                    {/* <AccountBalanceWalletIcon /> */}
                  </Avatar>
                    {/* <SwapHorizIcon /> */}
                    </ListItemIcon>
                    <ListItemText  primary={`Swap`} />
                    </ListItemButton>
                </ListItem>
              </Link>

            <Link  to='/referrals' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1738068352/gift_mjan0v.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#382f1b', color: '#fff'}}>
                    {/* <AccountBalanceWalletIcon /> */}
                  </Avatar>
                    {/* <Diversity3Icon /> */}
                    </ListItemIcon>
                    <ListItemText  primary={`Rewards Hub`} />
                    </ListItemButton>
                </ListItem>
              </Link>
          
                     
          </List>
          <List>
            
            <Divider/>

          <Link  to='/profile' component={RouterLink} underline="none" color="inherit">
            <ListItem  disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730471813/settings_tynzph.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#382f1b', color: '#fff'}}>
                    {/* <AccountBalanceWalletIcon /> */}
                  </Avatar>
                {/* <SettingsIcon/> */}
                </ListItemIcon>
                <ListItemText  primary={`Settings`} />
                </ListItemButton>
            </ListItem>
          </Link>
    </List>
      
    </Fragment>
  );
}