import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import { Link as RouterLink } from 'react-router-dom';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TradingButton from '../components/Trader/TradingButton';
import { useLocation } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';


export default function Footer() {
  const [value, setValue] = React.useState(0);
  const ref = React.useRef(null);
  const location = useLocation();
  const { pathname } = location;

  return (
    <Box sx={{ display: {md: 'none'},  pb: 7 }} ref={ref}>
      <CssBaseline />
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 20000  }} elevation={3}>
      {pathname === '/trade' ? 
         <TradingButton/>
        : 
        <BottomNavigation
        // 36492d
        // bce70c
        
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction component={RouterLink} to="/" label="Home" icon={
             <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1738083863/home_uktfab.png' sx={{ padding: '5px', width: 30, height: 30, color: '#fff'}}>
            </Avatar>  
          } />
          <BottomNavigationAction component={RouterLink} to="/start-trading" label="Markets" icon={
            <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1738083863/candle_l9uey7.png' sx={{ padding: '5px', width: 30, height: 30, color: '#fff'}}>
          </Avatar> } />

          <BottomNavigationAction component={RouterLink} to="/p2p" label="P2P" icon={
            <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1738068353/peer-to-peer_ekkqj5.png' sx={{ padding: '5px', width: 30, height: 30, color: '#fff'}}>
            </Avatar> 
            } />
          <BottomNavigationAction component={RouterLink} to="/package/Trading Bots" label="Earn" icon={
            <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1738068352/usdt_hmpls3.png' sx={{ padding: '5px', width: 30, height: 30, color: '#fff'}}>
            </Avatar> 
          } />

          <BottomNavigationAction component={RouterLink} to="/assets" label="Assets" icon={
            <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1738068347/wallet_1_vmr3li.png' sx={{ padding: '5px', width: 30, height: 30, color: '#fff'}}>
            </Avatar> 
          } />
          
          {/* <BottomNavigationAction label="Our Menu" icon={<MenuBookIcon />}/> */}
          
        </BottomNavigation>
        }
      </Paper>
    </Box>
  );
}
