import React, {useState, useEffect, Fragment} from 'react'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Slide from '@mui/material/Slide';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import apiClient from "../../request/http-common";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setRedirect } from '../Auth/historySlice';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { CardActions } from '@mui/material';
import { QRCode } from 'react-qrcode-logo';
import TextField from '@mui/material/TextField';
import { CardContent } from '@mui/material';
import Card from '@mui/material/Card';
import { useMutation } from 'react-query';
import { setFundings } from '../Wallet/walletSlice';
import {CardHeader, Divider} from '@mui/material';


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { useParams } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';

const Transaction = () => {
    const params = useParams();

    const [error, setErros] = useState('');
    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);
    const navigate = useNavigate();
    const trader = useSelector((state) => state.coins.p2pTrader);
    const [coin, setCoin] = useState('');
    const dispatch = useDispatch();
    const [amount, setAmount] = useState(null);
    const [buttonText, setbuttonText] = useState(null);
    const fundings = useSelector((state) => state.wallet.fundings);
    const pmtLink = `https://dashboard.whiterocksholdings.com/payment/${params.id}`;
  

    const { isLoading: isSendingRequest, mutate: postRequest } = useMutation(

        async () => {
          return await apiClient.post(`/api/confirm-funding`, {
            amount: amount,
            coin: params.id
          });
        },
        {
          onSuccess: (res) => {
            dispatch(setFundings(res.data.fundings))
            setCoin('');
    
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
            setTimeout(() => {
              navigate('/account-wallet');
            }, 2000);
          },
          onError: (err) => { 
            // setCoin('');
            if (err.response?.data.length) {
              let myerror = err.response?.data || err;         
            //   setErros(myerror.errors)  
              const event2 = new Event('processed');
              window.dispatchEvent(event2); 
            }else{
              
              let errMessage = err.response?.data.message || err;     
    
              localStorage.setItem('notification', JSON.stringify({message : `${errMessage}`, 'type': 'error' }));
              const event = new Event('newMessage');
              window.dispatchEvent(event);
              const event2 = new Event('processed');
              window.dispatchEvent(event2); 
            }
                
          },
        }
      );


  return (
    <div style={{marginTop: '70px'}}>

        {fundings.length > 0 &&

        fundings.map(funding =>(
            ((parseInt(funding.id) === parseInt(params.id)) && (funding.advertiser !== null )) &&
        <Fragment key={funding.id}>
        
        <List sx={{ width: '100%', bgcolor: 'transparent' }}>

           

        <Fragment>
        <ListItem alignItems="flex-start" sx={{cursor: 'pointer'}}>
            <ListItemText
            primary={funding.advertiser.trader_name}
            secondary={
                <React.Fragment>
                

                    <Box edge="end" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignContent: 'flex-start', alignItems: 'flex-start'}}>
                        <Typography sx={{fontSize: '11px'}} color="text.primary">
                        Quantity: {formatPrice(funding.advertiser.quantity)}
                        </Typography>

                        <Typography sx={{fontSize: '11px'}}>
                        Limits: {formatPrice(funding.advertiser.min)} - {formatPrice(funding.advertiser.max)}
                        </Typography>

                        <Typography style={{fontWeight: 'lighter'}} variant="caption" display="block" gutterBottom>
                            {funding.advertiser.payment_description}
                        </Typography>
                    </Box>

                </React.Fragment>
            }
            />

            

            <Box edge="end" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignContent: 'space-between', alignItems: 'center'}}>
                <Typography mx={1} sx={{fontSize: '11px'}} >
                    <Typography>
                    <Chip label={funding.status} color={funding.status === 'successful'? 'success' : 'primary'} />
                    </Typography>
                </Typography>

            </Box>
            
        </ListItem>
        <Divider variant="inset" component="li" />
        </Fragment>
        </List>

        <Box>
            <Card>
                {funding.wallet_name === null ?
                <Typography variant="h6">
                PAYMENT INFORMATION
                </Typography>
                :
                <Typography variant="h6">
                SELL ORDER DETAILS
                </Typography>
                }
                
                <Divider />
                {funding.wallet_name === null &&
                <CardContent sx={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'start'}}>
                    <Typography variant="subtitle2">
                {funding.advertiser.wallet_type === 'bank'? 'Payment Bank:' : 'Coin Name:'}  {funding.advertiser.coin_name}
                    </Typography>

                    <Typography variant="subtitle2">
                    {funding.advertiser.wallet_type === 'bank'? 'Bank Details:' : 'Wallet Address'}  {funding.advertiser.address}
                    </Typography>
                </CardContent>
                }
                
            </Card>
        </Box>

        <Card className='box' sx={{ borderRadius: '9px', height: '100%', margin: 1}}>
           

            <CardContent sx={{textAlign: 'center'}}>   


                   
            

            <FormControl fullWidth={true} sx={{  minWidth: 50, marginBottom: 2 }}>
                <InputLabel htmlFor="amount">Amount (USDT)</InputLabel>
                {(error !== '' && error.amount) ?

                <OutlinedInput
                onChange={(e)=>setAmount(e.target.value)}
                error
                readOnly
                value={funding.amount}
                id="amount"
                type={'number'}
                name="amount"
                label="Amount (USDT)"
                helperText={error.amount}
                />
                :
                <OutlinedInput
                onChange={(e)=>setAmount(e.target.value)}
                readOnly
                value={funding.amount}
                id="amount"
                type={'number'}
                name="amount"
                label="Amount (USDT)"
                />
                }
                <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                {error !== '' && error.amount}
                </Typography>
            </FormControl>

            {funding.wallet_name !== null ?
            <Alert severity="warning">Wait to receive payment before you confirm this order. Thank you."</Alert>
            :
            <Alert severity="warning">Please make sure to complete this payment before you click "Confirm Payment"</Alert>
            }

            

            </CardContent>

            <CardActions>  

            <Button onClick={postRequest} fullWidth={true} edge="end" size="small" color="primary" variant="contained"> 
            {isSendingRequest? 'sending...' : 'Confirm Payment'} 
            </Button>
        </CardActions>
        </Card>

        <Card sx={{marginTop: '20px'}}>
            <CardContent sx={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'start'}}>
                <Typography variant="h6" gutterBottom>
                    Advertiser Terms
                </Typography>
                <Typography variant="body2">
               {funding.advertiser.terms}
                </Typography>

                

            </CardContent>
            <CardActions>
            <CopyToClipboard onCopy={() => {setbuttonText( funding.advertiser.wallet_type === 'bank'? 'Payment Link Copied' : 'Payment Link Copied');
                setTimeout(() => {
                    setbuttonText(null);
                }, 2000);}} 
                
                text={pmtLink}>
                <FormControl  fullWidth={true} sx={{ minWidth: 50, marginBottom: 2 }}>
                    <TextField
                    readOnly
                    minRows={1} multiline                  
                    defaultValue={pmtLink}
                    id="address"
                    name="address"
                    type={'text'}
                    label= {buttonText !== null? buttonText: `Click to copy Payment Link `}
                    />
                </FormControl> 
            </CopyToClipboard>
            </CardActions>
        </Card>
        </Fragment>
            
        ))}
        
    </div>
  )
}

export default Transaction
