import React, {useState, useEffect} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useQuery } from 'react-query';

const CreateAddress = () => {
      
    
    const initwallet = {
        name : ''	,
        address : ''	,
        wallet_type: '',
        display_area: '',

        terms : '',
        percentage : '',
        orders : '',
        payment_description : '',
        max : '',
        min : '',
        quantity : '',
        trader_name : '',
        
    }
    
    
    const [error, setErros] = useState('');
    const [walletForm, setwalletForm] = useState(initwallet);
    const coinRates = useSelector((state) => state.coins.coinRates);

    const onChange = (e) =>
    setwalletForm({ ...walletForm, [e.target.name]: e.target.value });


    const { isLoading: isSendingRequest, mutate: postwallet } = useMutation(

        async () => {
          return await apiClient.post(`/api/create-address`, {
            coin_name : walletForm.name,	
            address : walletForm.address,
            wallet_type : walletForm.wallet_type,
            display_area : walletForm.display_area,

            terms : walletForm.terms,
            percentage : walletForm.percentage,
            orders : walletForm.orders,
            payment_description : walletForm.payment_description,
            max : walletForm.max,
            min : walletForm.min,
            quantity : walletForm.quantity,
            trader_name : walletForm.trader_name,
          });
        },
        {
          onSuccess: (res) => {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
          },
          onError: (err) => {   
            // let myerror = err.response?.data || err;         
            // setErros(myerror.errors)  
            // const event2 = new Event('processed');
            // window.dispatchEvent(event2); 
            
            if (err.response?.data.length) {
              let myerror = err.response?.data || err;         
          setErros(myerror.errors)               
          }else{
              let errorMessage = err.response?.data.message || err ;
              localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
              const event = new Event('newMessage');
              window.dispatchEvent(event);
          }

          },
        }
      );
      const onSubmit = () =>{
        const event3 = new Event('processing');
        window.dispatchEvent(event3);
        postwallet();
      } 


  return (
    <div style={{marginTop: '70px'}}>
        <Grid container px={2} mt={4}  mb={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                        CREATE WALLET ADDRESSES 
                    </Typography>
                    <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        Fill the form below to create wallets for payment
                    </Typography>
                    
                    <FormControl  fullWidth={true} sx={{ m: 1, minWidth: 50, marginBottom: '10px' }}>
                      <InputLabel htmlFor="display_area">Display Area</InputLabel>
                      <Select
                          onChange={onChange}
                          labelId="display_area"
                          name="display_area"
                          id="display_area"
                          label={`Display Area`}
                          >
                          <MenuItem selected value=''>
                            Select where to display this payment method
                            </MenuItem> 
                          <MenuItem selected value='withdrawals'>
                          withdrawals
                          </MenuItem> 
                          <MenuItem selected value='payments'>
                          deposits/payments
                          </MenuItem> 
                      </Select>                        
                    </FormControl>

                    <FormControl  fullWidth={true} sx={{ m: 1, minWidth: 50, marginBottom: '10px' }}>
                      <InputLabel htmlFor="wallet_type">Wallet Type</InputLabel>
                      <Select
                          onChange={onChange}
                          labelId="wallet_type"
                          name="wallet_type"
                          id="wallet_type"
                          label={`Wallet Type`}
                          >
                          <MenuItem selected value=''>
                            Select Option
                            </MenuItem> 
                          <MenuItem selected value='bank'>
                          Bank
                          </MenuItem> 
                          <MenuItem selected value='Crypto'>
                          Crypto
                          </MenuItem> 
                      </Select>                        
                    </FormControl>

                    <FormControl  fullWidth={true} sx={{ m: 1, minWidth: 50, marginBottom: '10px' }}>
                      <InputLabel htmlFor="name">Coin Name</InputLabel>
                      <Select
                          onChange={onChange}
                          labelId="name"
                          name="name"
                          id="name"
                          label={`Coin Name`}
                          >
                          <MenuItem selected value=''>
                          Select Coin
                          </MenuItem> 

                          {coinRates.length > 0 && 
                          coinRates.map((coin) =>(
                            <MenuItem key={coin.symbol} selected value={coin.symbol}>
                            {coin.symbol}
                            </MenuItem> 
                          ))}
                      </Select>                        
                    </FormControl>

                    <Typography variant='h4'>
                      OR
                    </Typography>

                    <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="wallet Name">{walletForm.wallet_type === 'bank' ? 'Bank Name' : 'Coin Name' } </InputLabel>
                            {(error !== '' && error.name) ?
                            <OutlinedInput
                            error
                            onChange={onChange}
                            id="name"
                            name="name"
                            type={'text'}
                            label={walletForm.wallet_type === 'bank' ? 'Bank Name' : 'Coin Name' }
                            helperText={error.name}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="name"
                            name="name"
                            type={'text'}
                            label={walletForm.wallet_type === 'bank' ? 'Bank Name' : 'Coin Name' }
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.name}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="address">{walletForm.wallet_type === 'bank' ? 'Account Details' : 'Address' }</InputLabel>
                            {(error !== '' && error.address) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="address"
                            type={'text'}
                            name="address"
                            label={walletForm.wallet_type === 'bank' ? 'Account Details' : 'Address' }
                            helperText={error.address}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="address"
                            type={'text'}
                            name="address"
                            label={walletForm.wallet_type === 'bank' ? 'Account Details' : 'Address' }
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.address}
                            </Typography>
                        </FormControl>    

                            <Typography variant='h4'>
                              FOR P2P ORDERS
                            </Typography>


                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="trader_name">{walletForm.wallet_type === 'bank' ? 'Trader Name' : 'Trader Name' }</InputLabel>
                            {(error !== '' && error.trader_name) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="trader_name"
                            type={'text'}
                            name="trader_name"
                            label={walletForm.wallet_type === 'bank' ? 'Trader Name' : 'Trader Name' }
                            helperText={error.trader_name}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="trader_name"
                            type={'text'}
                            name="trader_name"
                            label={walletForm.wallet_type === 'bank' ? 'Trader Name' : 'Trader Name' }
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.trader_name}
                            </Typography>
                        </FormControl>  

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="quantity">{walletForm.wallet_type === 'bank' ? 'Quantity (USD)' : 'Quantity (USD)' }</InputLabel>
                            {(error !== '' && error.quantity) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="quantity"
                            type={'number'}
                            name="quantity"
                            label={walletForm.wallet_type === 'bank' ? 'Quantity (USD)' : 'Quantity (USD)' }
                            helperText={error.quantity}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="quantity"
                            type={'number'}
                            name="quantity"
                            label={walletForm.wallet_type === 'bank' ? 'Quantity (USD)' : 'Quantity (USD)' }
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.quantity}
                            </Typography>
                        </FormControl> 


                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="min">{walletForm.wallet_type === 'bank' ? 'min (USD)' : 'min (USD)' }</InputLabel>
                            {(error !== '' && error.min) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="min"
                            type={'number'}
                            name="min"
                            label={walletForm.wallet_type === 'bank' ? 'min (USD)' : 'min (USD)' }
                            helperText={error.min}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="min"
                            type={'number'}
                            name="min"
                            label={walletForm.wallet_type === 'bank' ? 'min (USD)' : 'min (USD)' }
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.min}
                            </Typography>
                        </FormControl> 

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="max">{walletForm.wallet_type === 'bank' ? 'max (USD)' : 'max (USD)' }</InputLabel>
                            {(error !== '' && error.max) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="max"
                            type={'number'}
                            name="max"
                            label={walletForm.wallet_type === 'bank' ? 'max (USD)' : 'max (USD)' }
                            helperText={error.max}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="max"
                            type={'number'}
                            name="max"
                            label={walletForm.wallet_type === 'bank' ? 'max (USD)' : 'max (USD)' }
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.max}
                            </Typography>
                        </FormControl> 



                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="payment_description">{walletForm.wallet_type === 'bank' ? 'Payment Method' : 'Payment Method' }</InputLabel>
                            {(error !== '' && error.payment_description) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="payment_description"
                            type={'text'}
                            name="payment_description"
                            label={walletForm.wallet_type === 'bank' ? 'Payment Method' : 'Payment Method' }
                            helperText={error.payment_description}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="payment_description"
                            type={'text'}
                            name="payment_description"
                            label={walletForm.wallet_type === 'bank' ? 'Payment Method' : 'Payment Method' }
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.payment_description}
                            </Typography>
                        </FormControl>


                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="orders">{walletForm.wallet_type === 'bank' ? 'Orders' : 'Orders' }</InputLabel>
                            {(error !== '' && error.orders) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="orders"
                            type={'number'}
                            name="orders"
                            label={walletForm.wallet_type === 'bank' ? 'Num Orders' : 'Num Orders' }
                            helperText={error.orders}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="orders"
                            type={'number'}
                            name="orders"
                            label={walletForm.wallet_type === 'bank' ? 'Num Orders' : 'Num Orders' }
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.orders}
                            </Typography>
                        </FormControl> 

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="percentage">{walletForm.wallet_type === 'bank' ? 'percentage' : 'percentage' }</InputLabel>
                            {(error !== '' && error.percentage) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="percentage"
                            type={'number'}
                            name="percentage"
                            label={walletForm.wallet_type === 'bank' ? 'percentage' : 'percentage' }
                            helperText={error.percentage}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="percentage"
                            type={'number'}
                            name="percentage"
                            label={walletForm.wallet_type === 'bank' ? 'percentage' : 'percentage' }
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.percentage}
                            </Typography>
                        </FormControl>


                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="terms">{walletForm.wallet_type === 'bank' ? 'terms' : 'terms' }</InputLabel>
                            {(error !== '' && error.terms) ?

                            <OutlinedInput
                            onChange={onChange}
                            multiline
                            rows={4}

                            error
                            id="terms"
                            type={'text'}
                            name="terms"
                            label={walletForm.wallet_type === 'bank' ? 'terms' : 'terms' }
                            helperText={error.terms}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            multiline
                            rows={4}
                            id="terms"
                            type={'text'}
                            name="terms"
                            label={walletForm.wallet_type === 'bank' ? 'terms' : 'terms' }
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.terms}
                            </Typography>
                        </FormControl> 

                    </CardContent>   

                    <CardActions>
                        <Button onClick={onSubmit} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'Creating...' : 'Create wallet'}</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    </div>
  )
}

export default CreateAddress
