import React from 'react';
import { useSelector } from 'react-redux';
import Slider from "react-slick";
import { Box, Typography, Card } from '@mui/material';

export default function AssetsSlide() {
  const coins = useSelector((state) => state.coins.coinRates);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const formatPrice = (amount) => new Intl.NumberFormat('en-US', { 
    style: 'currency', 
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);

  const calculate24hrChange = (coin) => {
    let rate = 0;
    coins.forEach(coinRater => {
      if (coinRater.symbol === coin) {
        rate = parseFloat(coinRater.changePercent24Hr);
      }
    });

    return rate;
  }
  return (
    <Card sx={{ padding: '1px', marginY: '0px', bgcolor: 'transparent' }} elevation={0}>
      {coins && coins.length > 0 && (
        <Slider {...settings}>
          {coins.map((coin, index) => (
            <Box key={index} sx={{ 
              display: 'flex', 
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: 'row'
              
            }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                {coin.symbol}
              </Typography>

              <Typography variant="body2" color={calculate24hrChange(coin.symbol) >= 0 ? 'success.main' : 'error.main'}>
                {formatPrice(coin.priceUsd)}
                <Typography component="span" variant="caption" sx={{ marginLeft: '5px' }}>
                  ({calculate24hrChange(coin.symbol).toFixed(2)}%)
                </Typography>
              </Typography>
            </Box>
          ))}
        </Slider>
      )}
    </Card>
  );
}
