import React from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Fragment } from 'react';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import {yellow} from '@mui/material/colors';
import { setP2pTrader } from '../../request/CoinRates/coinSlice';
import { useDispatch } from 'react-redux';

const Sell = () => {
    const advertisers = useSelector((state) => state.coins.addresses);
    
    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);
    const navigate = useNavigate();
    const dispatch = useDispatch();
  return (
    <div>
      {advertisers.length > 0 && 

<List sx={{ width: '100%', bgcolor: 'transparent' }}>

    {advertisers.map((advertiser) =>(
        (advertiser.trader_name !== null && advertiser.display_area === 'withdrawals') &&
        <Fragment key={advertiser.id}>
            <ListItem alignItems="flex-start" sx={{cursor: 'pointer'}}
        onClick={()=>{  
        
          dispatch(setP2pTrader(advertiser)) 
                  
          setTimeout(() => {
            navigate('/seller');
          }, 100);
            }}
        >
  <ListItemAvatar>
        <Avatar
        sx={{ bgcolor: yellow[700], width: 24, height: 24  }}
        alt={advertiser.trader_name}
        src="/broken-image.jpg"
      />
    
</ListItemAvatar>
        <ListItemText
          primary={advertiser.trader_name}
          secondary={
            <React.Fragment>
              

                <Box edge="end" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignContent: 'flex-start', alignItems: 'flex-start'}}>
                    <Typography sx={{fontSize: '11px'}} color="text.primary">
                    Quantity: {formatPrice(advertiser.quantity)}
                    </Typography>

                    <Typography sx={{fontSize: '11px'}}>
                    Limits: {formatPrice(advertiser.min)} - {formatPrice(advertiser.max)}
                    </Typography>

                    <Typography style={{fontWeight: 'lighter'}} variant="caption" display="block" gutterBottom>
                        {advertiser.payment_description}
                    </Typography>
                </Box>

            </React.Fragment>
          }
        />

        

        <Box edge="end" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignContent: 'space-between', alignItems: 'center'}}>
            <Typography mx={1} sx={{fontSize: '11px'}} >
                <Typography>
                {advertiser.orders} Orders 
                </Typography>
                <Divider orientation="vertical" />
                <Typography>
                {parseInt(advertiser.percentage)}%
                </Typography>
            
           
            
            </Typography>

            <Typography mx={1} variant="caption">
            <Button style={{fontSize: '14px', fontWeight: 'bolder'}} color='error' variant="contained" size="small">
            Sell
            </Button>
            </Typography>
        </Box>
        
      </ListItem>
      <Divider variant="inset" component="li" />
      </Fragment>
    ))}



</List>

}

    </div>
  )
}

export default Sell
