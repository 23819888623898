import * as React from 'react';
import { green, pink } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import FolderIcon from '@mui/icons-material/Folder';
import PageviewIcon from '@mui/icons-material/Pageview';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link as RouterLink } from 'react-router-dom';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Grid } from '@mui/material';

export default function MobileMenu() {
  return (
     
    <Grid container px={2} mt={1}  mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={3}>
        <Box component={RouterLink}  to='/p2p' sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
          <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1738068353/peer-to-peer_ekkqj5.png' sx={{ padding: '5px', width: 40, height: 40}}>
            {/* <ArrowOutwardIcon /> */}
          </Avatar>  
          <Typography sx={{textAlign: 'center', fontSize: '12px'}}>P2P Trading</Typography>   
        </Box>
      </Grid>

      <Grid item xs={3}>
        <Box component={RouterLink}  to='/referrals' sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
          <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1738068352/gift_mjan0v.png' sx={{ padding: '5px', width: 40, height: 40}}>
            {/* <ArrowOutwardIcon /> */}
          </Avatar>  
          <Typography sx={{textAlign: 'center', fontSize: '12px'}}>Rewards Hub</Typography>   
        </Box>
      </Grid>

      <Grid item xs={3}>
        <Box component={RouterLink}  to='/account-wallet' sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
          <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1738068353/wallet_b7ymoh.png' sx={{ padding: '5px', width: 40, height: 40}}>
            {/* <ArrowOutwardIcon /> */}
          </Avatar>  
          <Typography sx={{textAlign: 'center', fontSize: '12px'}}>Deposits</Typography>   
        </Box>
      </Grid>

      <Grid item xs={3}>
        <Box component={RouterLink}  to='/package/Trading Bots' sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
          <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1738068352/robot_yozduf.png' sx={{ padding: '5px', width: 40, height: 40}}>
            {/* <ArrowOutwardIcon /> */}
          </Avatar>  
          <Typography sx={{textAlign: 'center', fontSize: '12px'}}>Trading Bot</Typography>   
        </Box>
      </Grid>

      <Grid item xs={3}>
        <Box component={RouterLink}  to='/swap-coin' sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
          <Avatar src='https://res.cloudinary.com/dqi4rtyvh/image/upload/v1739863653/swap_vw5hqj.png' sx={{ padding: '5px', width: 40, height: 40}}>
            {/* <ArrowOutwardIcon /> */}
          </Avatar>  
          <Typography sx={{textAlign: 'center', fontSize: '12px'}}>Swap</Typography>   
        </Box>
      </Grid>

      <Grid item xs={3}>
        <Box component={RouterLink}  to='/web3' sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
          <Avatar src='https://res.cloudinary.com/dqi4rtyvh/image/upload/v1738590761/web3_ztvzkf.png' sx={{ padding: '5px', width: 40, height: 40}}>
            {/* <ArrowOutwardIcon /> */}
          </Avatar>  
          <Typography sx={{textAlign: 'center', fontSize: '12px'}}>WEB3</Typography>   
        </Box>
      </Grid>

      <Grid item xs={3}>
        <Box component={RouterLink}  to='/copy-plans' sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
          <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1738068352/analytics_tjm1c0.png' sx={{ padding: '5px', width: 40, height: 40}}>
            {/* <ArrowOutwardIcon /> */}
          </Avatar>  
          <Typography sx={{textAlign: 'center', fontSize: '12px'}}>Insider</Typography>   
        </Box>
      </Grid>

      <Grid item xs={3}>
        <Box component={RouterLink}  to='/assets' sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
          <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1738068347/wallet_1_vmr3li.png' sx={{ padding: '5px', width: 40, height: 40}}>
            {/* <ArrowOutwardIcon /> */}
          </Avatar>  
          <Typography sx={{textAlign: 'center', fontSize: '12px'}}>Assets</Typography>   
        </Box>
      </Grid>
    </Grid>
  );
}